<!-- logo -->
<template>
  <div class="logo">
    <el-form ref="ruleForm" :model="ruleForm" label-width="110px">
      <!-- 微网刊logo设置 -->
      <div class="part">
        <div class="left-part">
          <img :src="logoImg" alt="" />
        </div>
        <div class="right-part">
          <div class="flex-content">
            <div class="left">
              <el-form-item label="微网刊logo：" size="zdy4" prop="logoCoverId">
                <div class="tiny-cover upload-img">
                  <img :src="logoCoverSrc" alt />
                  <el-upload class="upload-demo" :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" :auto-upload="true" :on-success="handleTinySuccess">
                    <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
                  </el-upload>
                </div>
              </el-form-item>
            </div>
            <div class="right">
              <el-form-item label="logo名称：" prop="logoName" size="zdy6">
                <el-input v-model="ruleForm.logoName" type="text" />
              </el-form-item>
              <el-form-item label="logo英文名称：" prop="logoNameEn" size="zdy6">
                <el-input v-model="ruleForm.logoNameEn" type="text" />
              </el-form-item>
              <el-form-item label="logo链接：" prop="logoHref" size="zdy6">
                <el-input v-model="ruleForm.logoHref" type="text" />
              </el-form-item>
            </div>
          </div>
          <div slot="tip" class="el-upload__tip">
            <el-button v-if="showResetBtn" @click="resetImg" style="margin: 0px 20px 0 0">重置</el-button>
            格式支持：jpg、png、jpeg <span class="betterSize">尺寸推荐：40*40</span>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { GetPictureStream, UploadPictureStream } from '@/api/configManage'
export default {
  name: 'logo',
  data() {
    return {
      morImg: require('@/assets/img/img_default.png'),
      GetPictureStream: GetPictureStream,
      UploadPictureStream: UploadPictureStream,
      logoCover: ''
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    logoImg: {
      default: require('@/assets/img/template_img/zk/module0.png')
    },
    // 是否显示重置按钮
    showResetBtn: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  watch: {
    magId() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
      })
    }
  },
  computed: {
    // 微网刊logo
    logoCoverSrc() {
      return this.logoCover ? this.logoCover : this.ruleForm.logoCoverId ? `${this.GetPictureStream}?pictureId=${this.ruleForm.logoCoverId}` : this.morImg
    }
  },
  created() {},
  methods: {
    //成功上传微网刊logo
    handleTinySuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.logoCover = URL.createObjectURL(file.raw)
      this.ruleForm.logoCoverId = ID
    },
    resetImg() {
      this.logoCover = ''
      this.ruleForm.logoCoverId = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  .left-part {
    height: 187px;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .tiny-cover {
    width: 100px;
    height: 100px;
    .upload-demo {
      width: 100px !important;
      height: 100px !important;
    }
  }
}
</style>
