<!-- color -->
<template>
  <div class="color">
    <el-form ref="ruleForm" :model="ruleForm" label-width="152px">
      <el-form-item label="微网刊主页配色：" size="zdy7" prop="color">
        <el-color-picker v-model="ruleForm.color" @active-change="colorChange"></el-color-picker>
      </el-form-item>
      <el-form-item label="微站文章详情页配色：" size="zdy7" prop="color">
        <el-color-picker v-model="ruleForm.weChatColor" @active-change="weChatColorChange"></el-color-picker>
      </el-form-item>
      <el-form-item label="关注推文：" size="zdy7">
        <el-switch v-model="ruleForm.subscribeSwitch" :active-value="0" :inactive-value="1"></el-switch>
        <div v-if="ruleForm.subscribeSwitch == 0" style="display:inline;margin-left:20px">
          阅读第
          <el-input
            type="number"
            :min="0"
            oninput="if(value<0)value=0"
            onkeyup="this.value=this.value.replace(/[^0-9]/g, '')"
            onafterpaste="this.value=this.value.replace(/[^0-9]/g, '')"
            style="width:100px"
            v-model="ruleForm.accessCount"
          ></el-input>
          篇文章后引导关注
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { colorHex } from '@/utils'
export default {
  name: 'color',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  watch: {
    magId() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
      })
    }
  },
  computed: {},
  created() {},
  methods: {
    //微网刊取色器色值转换#+16进制
    colorChange(value) {
      // this.ruleForm.color = colorHex(value)
    },
    weChatColorChange(value) {
      // this.ruleForm.weChatColor = colorHex(value)
    }
  }
}
</script>

<style lang="scss" scoped></style>
