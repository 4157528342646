<!-- browseBackIssues 含能材料过刊浏览 -->
<template>
  <div class="browseBackIssues">
    <el-form ref="ruleForm" :model="ruleForm" label-width="110px">
      <div class="part">
        <div class="left-part">
          <img :src="browseBackIssuesImg" alt="" />
        </div>
        <div class="right-part">
          <el-form-item label="显示条数：" prop="backMagSize">
            <el-select v-model="ruleForm.backMagSize" placeholder="请选择" style="width: 100px">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'browseBackIssues',
  data() {
    return {
      options: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
        { label: '10', value: 10 },
        { label: '11', value: 11 },
        { label: '12', value: 12 }
      ]
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    browseBackIssuesImg: {
      default: require('@/assets/img/template_img/hncl/module11.png')
    }
  },
  components: {},
  computed: {},
  created() {},
  methods: {}
}
</script>
<style lang="scss">
.browseBackIssues {
  .el-select {
    float: left;
  }
}
</style>
<style lang="scss" scoped>
.browseBackIssues {
  .left-part {
    height: 200px;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
