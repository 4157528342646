<!-- informationRangeSetting -->
<template>
  <div class="informationRangeSetting"></div>
</template>

<script>
export default {
  name: 'informationRangeSetting',
  data() {
    return {}
  },
  props: {},
  components: {},
  computed: {},
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
