<template>
  <div class="style-settings">
    <el-form class="style-settings-ruleForm" ref="publicationRuleForm" :model="publicationForm" :rules="type === 'editIssue' ? editIssuePublicationrules : publicationrules" label-width="160px">
      <!-- 本刊模板名称 -->
      <div class="part" v-if="type === 'editIssue'">
        <el-form-item label="本刊模板名称：" prop="name" class="common-style">
          <el-select v-model="publicationForm.name" @change="styleConfigListChange" placeholder="请选择">
            <el-option v-for="item in styleConfigList" :key="item.id" :label="item.name" :value="item.name" />
          </el-select>
        </el-form-item>
      </div>
      <div class="part" v-if="type === 'editIssue'">
        <el-form-item label="本期语言设置：" prop="name" class="common-style">
          <el-select v-model="publicationForm.language" placeholder="请选择">
            <el-option v-for="item in languageList" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item>
      </div>
      <div class="part" v-else>
        <el-form-item label="本刊模板名称：" prop="name" class="common-style">
          <el-input id="nameItem" placeholder="请输入" v-model="publicationForm.name"></el-input>
        </el-form-item>
      </div>
      <!-- 网刊模板 -->
      <div class="part template-list-box">
        <el-form-item label="通用组件模板：" prop="templateId" class="template-list">
          <el-carousel ref="carousel" :autoplay="false" :loop="false" arrow="hover" indicator-position="none" height="205px" class="cars">
            <el-carousel-item v-for="(item, index) in Math.ceil(templateData.length / 6)" :key="index">
              <div class="cars_div">
                <dl v-for="radioItem in templateData.slice((item - 1) * 6, item * 6)" :key="radioItem.id">
                  <dt class="clearfix">
                    <div class="img-container">
                      <el-image :src="`${GetPictureStream}?pictureId=${radioItem.coverId}&type=small`" class="template-item-img" alt="" />
                    </div>
                  </dt>
                  <dd>
                    <el-radio v-model="publicationForm.templateId" :label="radioItem.id">{{ radioItem.name }}</el-radio>
                  </dd>
                </dl>
              </div>
            </el-carousel-item>
          </el-carousel>
          <!-- <div class="template-item" v-for="(item, index) in templateData" :key="index">
            <el-image class="template-item-img" :src="`${GetPictureStream}?pictureId=${item.coverId}`" :preview-src-list="previewSrcList" alt />
            <el-radio v-model="publicationForm.templateId" :label="item.id">{{ item.name }}</el-radio>
          </div> -->
        </el-form-item>
      </div>
      <!-- 选择模板后才显示 -->
      <div v-if="showDetailsSetting">
        <!-- 自科模板 -->
        <zkType
          v-if="currentmoduleInfo.type === 1"
          :ruleForm="publicationForm"
          showWeChatShare
          :showMag="module6Show"
          :showSubject="module9Show"
          :showCarousel="module1Show1 && showCarouselSettings"
          :carouselTableData="carouselTableData"
          :chooseCarouseArticleList="chooseCarouseArticleList"
        ></zkType>
        <!-- 社科模板 -->
        <skType
          v-if="currentmoduleInfo.type === 2"
          :ruleForm="publicationForm"
          :showMag="module6Show"
          :showCarousel="module1Show1 && showCarouselSettings"
          :carouselTableData="carouselTableData"
          showSkCover
          showWeChatShare
        ></skType>
        <!-- 医学模板 -->
        <yxType v-if="currentmoduleInfo.type === 3" :ruleForm="publicationForm" :showSubject="module9Show" :showMag="module6Show" showWeChatShare></yxType>
        <!-- 含能材料模板 -->
        <hnclType
          v-if="currentmoduleInfo.type === 4"
          :ruleForm="publicationForm"
          :showCodes="module7Show"
          :showSubject="module9Show"
          :showEnfo="module10Show"
          :showBrowseBackIssues="module11Show"
        ></hnclType>
        <!-- 专刊简介 -->
        <div class="part" v-if="type === 'editIssue'">
          <div class="left-part">
            <img src="../../../assets/img/previousCatalog/intro.png" alt="" />
          </div>
          <div class="right-part">
            <el-form-item label="专刊简介：" prop="introduction" size="zdy6">
              <el-input v-model="publicationForm.introduction" type="text" />
            </el-form-item>
          </div>
        </div>
        <!-- 文章地址类型优先级 -->
        <div class="part" v-if="showHtmlOrder">
          <el-form-item class="type-arr" label="文章地址类型优先级：" prop="htmlOrder">
            <el-radio-group v-model="radio" v-for="(item, index) in typeArr" :key="index">
              <el-radio :label="item.value">{{ item.label }}</el-radio>
              <span class="dyIcon" v-if="index !== typeArr.length - 1">></span>
            </el-radio-group>
            <div class="btn">
              <el-button v-if="showLeft" size="small" round icon="el-icon-back" @click="toLeft">左移</el-button>
              <el-button v-if="showRight" size="small" round icon="el-icon-right" @click="toRight">右移</el-button>
            </div>
          </el-form-item>
        </div>
      </div>
      <el-button class="save-btn" @click="savePublicationConfig">保存</el-button>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GetPictureStream, getTemplate } from '@/api/configManage'
import { GetTemplateList } from '@/api/selfPush'
import { SaveStyleConfig, EditStyleConfig, GetListConfig, GetConfig } from '@/api/weChatPush'
import zkType from './template/zkType'
import skType from './template/skType'
import yxType from './template/yxType'
import hnclType from './template/hnclType'
export default {
  name: 'styleSettings',
  components: {
    zkType,
    skType,
    yxType,
    hnclType
  },
  props: {
    carouselTableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    chooseId: {
      type: Number,
      default: null
    },
    // 是否显示轮播图设置
    showCarouselSettings: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    'publicationForm.templateId'(n) {
      if (n) {
        this.getTemplateDetail(n)
        // this.computedInitialIndex()
        this.showDetailsSetting = true
        this.$refs['publicationRuleForm'].validate(() => {})
      } else {
        this.showDetailsSetting = false
      }
    },
    radio() {
      this.computedShowLeftRight()
    }
  },
  computed: {
    ...mapGetters({
      platMagId: 'platMagId',
      platTenantId: 'platTenantId',
      magId: 'magId'
    }),
    // 期刊底部显示条件
    module6Show() {
      let module6 = this.mouldConfig.modlue6
      return module6 && module6.isShow
    },
    // 二维码显示
    module7Show() {
      let module7 = this.mouldConfig.module7
      return module7 && module7.isShow
    },
    // 期刊顶部显示条件
    module9Show() {
      let module9 = this.mouldConfig.modlue9
      return module9 && module9.isShow
    },
    //轮播图设置 单独设置每期样式时才显示
    module1Show1() {
      let module1 = this.mouldConfig.modlue1
      return module1 && module1.isShow && this.type === 'editIssue' && this.chooseId
    },
    //轮播图左侧图片 社科类才显示
    module1Show2() {
      let module1 = this.mouldConfig.modlue1
      return module1 && module1.isShow && this.templateType == 2
    },
    showHtmlOrder() {
      return this.type === 'editIssue'
    },
    //每期英文信息背景图设置 含能材料
    module10Show() {
      let module10 = this.mouldConfig.modlue10
      return module10 && module10.isShow
    },
    //过刊浏览显示条数 含能材料
    module11Show() {
      let module11 = this.mouldConfig.modlue11
      return module11 && module11.isShow && this.type === 'editIssue'
    }
  },

  data() {
    return {
      morImg: require('@/assets/img/img_default.png'),
      GetPictureStream: GetPictureStream,
      publicationForm: {},
      newFormData: {
        name: '', //本刊模板名称
        magId: '', // 期刊id
        templateId: '', //网刊模板id
        weChatShareCoverId: '', //微信分享链接图片id
        weChatShareTitle: '', //微信分享标题
        weChatShareContent: '', //微信分享内容
        subjectTitleCoverId: '', //专题顶部介绍附件id
        subjectCoverId: '', //专题顶部介绍封面id
        subjectInfo: '', //专题顶部介绍
        subjectTitle: '', //专题顶部标题
        subjectHref: '', //专题顶部跳转
        subjectEnTitle: '', // 英文标题
        subjectTiltleColor: '', //专题顶部标题配色
        magCoverId: '', //期刊底部介绍封面附件id
        magHref: '', //期刊底部介绍封面url
        magInfo: '', //期刊底部介绍
        magTitle: '', //期刊底部标题
        magTitleColor: '', //期刊底部介绍标题配色
        magHrefInfo: '', // 期刊底部封面故事介绍
        deRotationCoverId: '', // 轮播图左侧图片
        docIds: '', //轮播图自选文章Ids
        htmlOrder: '', // 文章地址类型优先级 htmlUrl:全文 abstractUrl:摘要 wechatUrl:微信 url :知网
        articleBackdrop: '', // 组件10英文信息背景图片
        backMagSize: 3, // 组件11过刊浏览图片条数
        introduction: '' // 目录简介
      },
      editIssuePublicationrules: {
        name: [{ message: '请输入本刊模板名称', trigger: 'blur', required: false }],
        templateId: [{ message: '请选择网刊模板', trigger: 'change', required: true }]
      },
      publicationrules: {
        name: [{ message: '请输入本刊模板名称', trigger: 'blur', required: true }],
        templateId: [{ message: '请选择网刊模板', trigger: 'change', required: true }]
      },
      mouldConfig: {},
      templateData: '',
      templateType: 1,
      previewSrcList: [], // 模板设置预览url
      type: '', // 类型, 区分是新建还是编辑
      showDetailsSetting: false,
      chooseCarouseSortArticleList: [],
      styleConfigList: [],
      languageList: [
        { name: '中文', value: 'zh' },
        { name: '英文', value: 'en' }
      ],
      basicConfigData: '',
      module6FormData: {
        // 期刊底部
        magCoverId: '',
        magTitle: '',
        magTitleColor: '',
        magHref: '',
        magInfo: '',
        magHrefInfo: ''
      },
      module9FormData: {
        //期刊顶部
        subjectCoverId: '',
        subjectTitle: '',
        subjectTiltleColor: '',
        subjectHref: '',
        subjectInfo: ''
      },
      weChatShareFormData: {
        weChatShareCoverId: '',
        weChatShareTitle: '',
        weChatShareContent: ''
      },
      typeArr: [],
      radio: '',
      showLeft: false,
      showRight: false,
      chooseIndex: 0,
      currentmoduleInfo: '', // 当前选中组件模板信息
      chooseCarouseArticleList: [],
      initialIndex: 0,
      infoData: {}
    }
  },
  created() {},
  mounted() {},
  methods: {
    //重置表单
    resetForm() {
      this.$refs['publicationRuleForm'].resetFields()
    },
    // 根据传入的type取表单字段
    initFormData(type, formData) {
      this.type = type
      if (this.type === 'new') {
        // 新建
        this.publicationForm = this.newFormData
      } else if (this.type === 'edit') {
        this.publicationForm = { ...formData }
        setTimeout(() => {
          // 编辑
          this.$refs['publicationRuleForm'].validate(() => {})
        })
      } else if (this.type === 'editIssue') {
        // 编辑每期样式
        this.publicationForm = { ...formData }
        if (this.publicationForm.htmlOrder) {
          this.typeArr = []
          let arr = this.publicationForm.htmlOrder.split(',')
          let newArr = []
          if (arr.indexOf('pubUrl') === -1) {
            this.typeArr.push({ label: '微站', value: 'pubUrl' })
            this.publicationForm.htmlOrder = 'pubUrl,' + this.publicationForm.htmlOrder
          }
          arr.map((item, index) => {
            if (item === 'pubUrl') {
              this.typeArr.push({ label: '微站', value: 'pubUrl' })
            } else if (item === 'htmlUrl') {
              this.typeArr.push({ label: '全文', value: 'htmlUrl' })
            } else if (item === 'wechatUrl') {
              this.typeArr.push({ label: '微信', value: 'wechatUrl' })
            } else if (item === 'abstractUrl') {
              this.typeArr.push({ label: '摘要', value: 'abstractUrl' })
            } else if (item === 'url') {
              this.typeArr.push({ label: '知网', value: 'url' })
            }
          })
        }
      }
      if (this.publicationForm.templateId) {
        this.showDetailsSetting = true
      } else {
        this.showDetailsSetting = false
      }
    },
    // 获取微网刊样式模板
    getStyleConfigList() {
      let params = {
        magId: this.magId,
        page: 0,
        size: 999
      }
      GetListConfig(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.styleConfigList = data.content
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取网刊配置模板数据
    getTemplateList() {
      let params = {
        size: 99,
        type: '',
        templateType: 0
      }
      GetTemplateList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.templateData = data.content
          this.computedInitialIndex()
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    computedInitialIndex() {
      if (this.templateData.length > 0) {
        this.templateData.map((item, index) => {
          if (this.publicationForm.templateId === item.id) {
            this.initialIndex = index
          }
        })
        this.$nextTick(() => {
          this.$refs.carousel.setActiveItem(Math.floor(this.initialIndex / 6))
        })
      }
    },
    // 根据网刊设置模板id获取模板数据
    getTemplateDetail(id) {
      const params = {
        id: id
      }
      getTemplate(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.currentmoduleInfo = data
          this.templateType = data.type
          this.mouldConfig = JSON.parse(data.mouldConfig)
          // 期刊底部介绍
          if (this.module6Show) {
            for (var item in this.module6FormData) {
              if (this.publicationForm.hasOwnProperty(item)) {
                if (item === 'magTitle' && this.templateType === 1) {
                  this.publicationForm[item] = this.publicationForm[item] ? this.publicationForm[item].slice(0, 6) : this.module6FormData[item] ? this.module6FormData[item].slice(0, 6) : ''
                }
                this.publicationForm[item] = this.publicationForm[item] || this.module6FormData[item]
              }
            }
          }
          // 期刊顶部介绍
          if (this.module9Show) {
            for (var item in this.module9FormData) {
              if (this.publicationForm.hasOwnProperty(item)) {
                if (item === 'subjectTitle' && this.templateType === 1) {
                  this.publicationForm[item] = this.publicationForm[item] ? this.publicationForm[item].slice(0, 6) : this.module9FormData[item] ? this.module9FormData[item].slice(0, 6) : ''
                }
                this.publicationForm[item] = this.publicationForm[item] || this.module9FormData[item]
              }
            }
          }
        }
      })
    },
    // 获取基础配置
    getConfig(type, formData) {
      let params = {
        tenantId: this.platTenantId,
        publicationId: this.platMagId,
        magId: this.magId
      }
      GetConfig(params).then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: data.message
          })
        } else {
          this.basicConfigData = data
          this.getTemplateList()
          this.type = type
          if (this.type === 'new') {
            // 新建
            this.publicationForm = this.newFormData
          } else if (this.type === 'edit') {
            this.publicationForm = { ...formData }
            setTimeout(() => {
              // 编辑
              this.$refs['publicationRuleForm'].validate(() => {})
            })
          } else if (this.type === 'editIssue') {
            // 编辑每期样式
            this.getStyleConfigList() //获取微网刊样式模板
            this.publicationForm = { ...formData }
            this.infoData = { ...formData }
            if (this.publicationForm.htmlOrder) {
              this.typeArr = []
              let arr = this.publicationForm.htmlOrder.split(',')
              let newArr = []
              if (arr.indexOf('pubUrl') === -1) {
                this.typeArr.push({ label: '微站', value: 'pubUrl' })
                this.publicationForm.htmlOrder = 'pubUrl,' + this.publicationForm.htmlOrder
              }
              arr.map((item, index) => {
                if (item === 'pubUrl') {
                  this.typeArr.push({ label: '微站', value: 'pubUrl' })
                } else if (item === 'htmlUrl') {
                  this.typeArr.push({ label: '全文', value: 'htmlUrl' })
                } else if (item === 'wechatUrl') {
                  this.typeArr.push({ label: '微信', value: 'wechatUrl' })
                } else if (item === 'abstractUrl') {
                  this.typeArr.push({ label: '摘要', value: 'abstractUrl' })
                } else if (item === 'url') {
                  this.typeArr.push({ label: '知网', value: 'url' })
                }
              })
            }
          }
          if (this.publicationForm.templateId) {
            this.getTemplateDetail(this.publicationForm.templateId)
            this.showDetailsSetting = true
          } else {
            this.showDetailsSetting = false
          }
          // 每期封面和微信分享封面为空就可以替换，不为空就不能替换
          for (var item in data) {
            if (item === 'magCoverId') {
              // 每期封面不取基础配置的
              this.module6FormData[item] = this.publicationForm[item]
            } else if (this.module6FormData.hasOwnProperty(item)) {
              this.module6FormData[item] = data[item]
            } else if (item === 'color') {
              //期刊底部介绍标题/专题顶部标题配色取基础配置color
              this.module6FormData['magTitleColor'] = data[item]
              this.module9FormData['subjectTiltleColor'] = data[item]
            }
            if (this.module9FormData.hasOwnProperty(item)) {
              this.module9FormData[item] = data[item]
            }
            if (this.weChatShareFormData.hasOwnProperty(item)) {
              this.weChatShareFormData[item] = data[item]
            }
          }
          for (var item in this.weChatShareFormData) {
            if (item === 'weChatShareCoverId') {
              this.weChatShareFormData[item] = this.publicationForm[item]
            } else if (this.publicationForm.hasOwnProperty(item)) {
              this.publicationForm[item] = this.publicationForm[item] || this.weChatShareFormData[item]
            }
          }
        }
      })
    },
    // 改变本刊模板名称
    styleConfigListChange() {
      this.$refs.publicationRuleForm.clearValidate()
      this.styleConfigList.map((item, index) => {
        if (this.publicationForm.name === item.name) {
          for (var it in item) {
            if (this.newFormData.hasOwnProperty(it)) {
              if ((it === 'magCoverId' || it === 'weChatShareCoverId' || it === 'weChatShareTitle' || it === 'weChatShareContent') && this.infoData[it]) {
                this.publicationForm[it] = this.publicationForm[it]
              } else {
                this.publicationForm[it] = item[it]
              }
            }
          }
          this.initFormData('editIssue', this.publicationForm)
        }
      })
      this.computedInitialIndex()
    },
    //新建编辑保存样式设置
    savePublicationConfig() {
      this.publicationForm.magId = this.magId
      this.$refs['publicationRuleForm'].validate((valid) => {
        if (!valid) {
          if (this.type !== 'editIssue') {
            document.getElementById('nameItem').focus()
          } else {
            window.scrollTo(0, 0)
          }
          return false
        } else {
          if (this.type === 'new') {
            SaveStyleConfig(this.publicationForm).then((res) => {
              if (res && res.data && res.data.status === 0) {
                this.$message({
                  message: '新建成功！',
                  duration: 1500,
                  type: 'success'
                })
                this.$emit('savePublicationConfig')
              } else {
                this.$message.error(res.data.message)
              }
            })
          } else if (this.type === 'edit') {
            EditStyleConfig(this.publicationForm).then((res) => {
              if (res && res.data && res.data.status === 0) {
                this.$message({
                  message: '修改成功！',
                  duration: 1500,
                  type: 'success'
                })
                this.$emit('savePublicationConfig')
              } else {
                this.$message.error(res.data.message)
              }
            })
          } else if (this.type === 'editIssue') {
            this.$emit('savePublicationConfig', this.publicationForm, 'editIssue')
          }
        }
      })
    },
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0]
      return arr
    },
    // 文章优先级左移
    toLeft() {
      this.typeArr.map((item, index) => {
        if (item.value === this.radio) {
          this.chooseIndex = index
        }
      })
      let newArr = this.swapItems(this.typeArr, this.chooseIndex, this.chooseIndex - 1)
      this.typeArr = newArr
      this.computedShowLeftRight()
    },
    // 文章优先级右移
    toRight() {
      this.typeArr.map((item, index) => {
        if (item.value === this.radio) {
          this.chooseIndex = index
        }
      })
      let newArr = this.swapItems(this.typeArr, this.chooseIndex, this.chooseIndex + 1)
      this.typeArr = newArr
      this.computedShowLeftRight()
    },
    computedShowLeftRight() {
      this.typeArr.map((item, index) => {
        if (item.value === this.radio) {
          this.chooseIndex = index
        }
      })
      this.showLeft = this.chooseIndex !== 0
      this.showRight = this.chooseIndex !== this.typeArr.length - 1
      this.publicationForm.htmlOrder = ''
      this.typeArr.map((item, index) => {
        this.publicationForm.htmlOrder = index === this.typeArr.length - 1 ? this.publicationForm.htmlOrder + item.value : this.publicationForm.htmlOrder + item.value + ','
      })
    }
  }
}
</script>
<style lang="scss">
.style-settings {
  .template-list {
    .el-form-item__content {
      display: flex;
      width: 980px;
      overflow: auto;
      padding: 10px;
      border: 1px solid #ddd;
    }
    .el-form-item__error {
      top: 215px;
    }
    .el-radio {
      display: flex;
      align-items: center;
      line-height: 32px;
    }
    .el-radio__label {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .cars_div {
      dd {
        label {
          display: inline;
        }
      }
    }
  }
  .carousel-settings {
    .el-dialog__wrapper {
      background: rgba(0, 0, 0, 0.3) !important;
      padding: 0;
    }
    .el-dialog__title {
      font-weight: 600;
    }
  }
  .template-list {
    .template-item-img {
      img {
        object-fit: contain;
      }
    }
  }
  .editor_box {
    width: 790px;
  }
  .type-arr {
    .btn {
      margin-left: 40px;
    }
    .el-form-item__content {
      display: flex;
      align-items: center;
      font-size: 14px;
      height: 32px;
      .dyIcon {
        margin-right: 30px;
        font-size: 18px;
      }
    }
  }
}
.style-settings {
  .betterSize {
    margin-left: 30px;
  }
  .part {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    .left-part {
      width: 35%;
      img {
        width: 100%;
      }
    }
    .right-part {
      width: 60%;
      border: 1px dashed #bbbbbb;
      padding: 20px 20px 0 20px;
      .flex-content {
        display: flex;
        .left {
          width: 45%;
        }
        .right {
          width: 55%;
        }
      }
    }
    .upload-img {
      position: relative;
      border: 1px solid #ddd;
      background: #f4f4f4;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .upload-demo {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 156px;
      height: 231px;
      .upload-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
    .el-upload__tip {
      color: #999;
      font-size: 14px;
      margin: 0 0 20px 110px;
      text-align: left;
    }
    .el-tabs--border-card > .el-tabs__content {
      padding: 0;
    }
  }
}
</style>
<style scoped lang="scss">
.style-settings {
  //   margin: 40px 160px;
  position: relative;
  overflow: auto;
  .title {
    font-size: 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    padding: 6px 20px;
    display: inline-block;
    // float: left;
    color: #265cca;
  }
  .pre-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    padding-left: 10px;
    cursor: pointer;
    color: #91949d;
    text-decoration: underline;
    &:hover {
      color: #265cca;
    }
  }
  .style-settings-ruleForm {
    .part {
      display: inline-block;
      width: 100%;
      margin: 10px 0;
      display: flex;
      justify-self: left;
      .rightContent {
        width: 56%;
      }
    }
    .mag-cover-box {
      .box {
        position: relative;
        overflow: hidden;
        .mag-cover {
          height: 231px;
          width: 156px;
          border: 1px solid #ddd;
          background: #f4f4f4;
          overflow: hidden;
          position: relative;
          cursor: pointer;
          img {
            height: auto;
            max-height: 100%;
            max-width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .upload-demo {
          width: 156px;
          height: 231px;
          position: absolute;
          top: 0;
        }
      }
    }
    .postion {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    .tip {
      color: #999;
      font-size: 14px;
      margin-top: 10px;
      text-align: left;
      line-height: 24px;
    }
    // 轮播图设置
    .carousel-settings {
      width: 100%;
      .settings-btn {
        color: #fff;
        background-color: #265cca;
        border-radius: 4px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        width: 40%;
        margin: 0 auto 20px;
        cursor: pointer;
      }
    }
  }
  // 模板设置列表
  .cars {
    width: 100%;
    .cars_div {
      display: flex;
      dl {
        cursor: pointer;
        margin-right: 27px;
      }
      dd {
        overflow: hidden;
        width: 140px;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
      .template-item-img {
        width: 140px;
        height: 180px;
        display: block;
      }
    }
  }
  .template-list-box {
    .template-name {
      width: 140px;
    }
    .template-list {
      .template-item {
        margin-right: 20px;
        display: inline-block;
        .template-item-img {
          width: 140px;
          height: 180px;
          display: block;
        }
      }
    }
  }
  .save-btn {
    width: 120px;
    height: 40px;
    margin-left: 43%;
    &:hover {
      color: #fff;
      background: #265cca;
      border-block-color: #265cca;
    }
  }
  // 目录列表
  .intro {
    display: flex;
  }
}
</style>
