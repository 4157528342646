<!-- carousel -->
<template>
  <div class="carousel">
    <el-form ref="ruleForm" :model="ruleForm" label-width="110px">
      <div class="part">
        <div class="left-part">
          <img :src="carouselImg" alt="" />
        </div>
        <div class="right-part">
          <el-form-item label="轮播图设置：" class="carousel-settings" prop="magCoverId">
            <div class="settings-btn" @click="showCarousel">
              请选择要设置的轮播图文章
            </div>
            <el-dialog class="carouselDialog" width="1000px" top="10vh" title="请选择要设置的轮播图文章" :visible.sync="showCarouselDialog" :show-close="false">
              <SimpleTable
                ref="carouselTable"
                noSerial
                noPagination
                hasSelect
                maxHeight="440px"
                :tableData="carouselTableData"
                :columnData="carouselArticleColumn"
                @onChangeSelection="handleCarouseSelectionChange"
              />
              <div slot="footer" class="dialog-footer" style="margin: 30px auto 0">
                <el-button type="primary" round @click="saveCarouseSettings">确 定</el-button>
              </div>
            </el-dialog>
            <SimpleTable
              ref="carouselSortTable"
              noPagination
              showChoose
              showUpBottom
              maxHeight="440px"
              style="width:584px;margin:auto"
              :tableData="chooseCarouseArticleList"
              :columnData="[{ title: '标题', prop: 'topic', notSortable: true }]"
              @onChangeToTop="handleToTop"
              @onChangeToBottom="handleToBottom"
            />
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import SimpleTable from '@/components/SimpleTable'
export default {
  name: 'carousel',
  data() {
    return {
      showCarouselDialog: false,
      carouselArticleColumn: [
        {
          title: '推送文章标题',
          prop: 'topic',
          notSortable: true,
          clickParam: 'link',
          special: true
        },
        {
          title: '机构',
          prop: 'org',
          notSortable: true
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '刊期',
          prop: 'pubYearStage',
          notSortable: true
        },
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true
        },
        {
          title: 'URL',
          prop: 'link',
          notSortable: true
        }
      ],
      chooseCarouseArticleList: []
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    carouselTableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    carouselImg: {
      default: require('@/assets/img/template_img/zk/module1.png')
    }
  },
  components: {
    SimpleTable
  },
  watch: {
    magId() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
      })
    }
  },
  computed: {},
  mounted() {
    if (this.ruleForm.docIds) {
      this.chooseCarouseArticleList = []
      let arr = this.ruleForm.docIds.split(',')
      arr.map((item) => {
        this.carouselTableData.map((it) => {
          if (it.docId == item) {
            this.chooseCarouseArticleList.push(it)
          }
        })
      })
    }
  },
  methods: {
    // 显示选择轮播图列表
    showCarousel() {
      this.showCarouselDialog = true
      this.$nextTick(() => {
        if (this.chooseCarouseArticleList) {
          this.chooseCarouseArticleList.map((item) => {
            this.$refs.carouselTable.$refs.simpleTable.toggleRowSelection(item, true)
          })
        }
      })
    },
    // 选择的轮播图数据
    handleCarouseSelectionChange(val) {
      this.chooseCarouseArticleList = val
    },
    // 轮播图点击标题跳转页面
    handleResultOpenUrl(chooseItem, columnItem) {
      const { clickParam = '' } = columnItem
      if (clickParam && clickParam !== '' && chooseItem[clickParam] !== '') {
        window.open(chooseItem[clickParam])
      }
    },
    // 保存轮播图选择数据
    saveCarouseSettings() {
      this.showCarouselDialog = false
      if (this.chooseCarouseArticleList.length > 0) {
        let articleIds = ''
        this.chooseCarouseArticleList.map((item, index) => {
          articleIds = articleIds + item.docId + (index === this.chooseCarouseArticleList.length - 1 ? '' : ',')
        })
        this.ruleForm.docIds = articleIds
      }
    },
    // 轮播图列表数据上移
    handleToTop(index, id) {
      let newArr = this.swapItems(this.chooseCarouseArticleList, index, index - 1)
      this.chooseCarouseArticleList = newArr
      this.$nextTick(() => {
        this.$refs.carouselSortTable.handleRowClick(this.chooseCarouseArticleList[index - 1])
      })
      this.saveCarouseSettings()
    },
    // 轮播图列表数据下移
    handleToBottom(index, id) {
      let newArr = this.swapItems(this.chooseCarouseArticleList, index, index + 1)
      this.chooseCarouseArticleList = newArr
      this.$nextTick(() => {
        this.$refs.carouselSortTable.handleRowClick(this.chooseCarouseArticleList[index + 1])
      })
      this.saveCarouseSettings()
    },
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0]
      return arr
    }
  }
}
</script>

<style lang="scss">
.carousel {
  .carousel-settings {
    .el-dialog__wrapper {
      background: rgba(0, 0, 0, 0.3) !important;
      padding: 0;
    }
    .el-dialog__title {
      font-weight: 600;
    }
  }
}
</style>
<style lang="scss" scoped>
.carousel {
  .carousel-settings {
    width: 100%;
    .settings-btn {
      color: #fff;
      background-color: #265cca;
      border-radius: 4px;
      text-align: center;
      height: 40px;
      line-height: 40px;
      width: 40%;
      margin: 0 auto 20px;
      cursor: pointer;
    }
  }
}
</style>
