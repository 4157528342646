<template>
  <div>
    <div class="pTitle">{{ platMagName }}</div>
    <div>
      <div class="w specialPushResults">
        <el-divider content-position="center">推送结果</el-divider>
        <p>PUSH RESULTS</p>
        <!--      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" text-color="#333" active-text-color="#265cca" menu-trigger="hover" @select="handleSelect">-->
        <!--        <el-menu-item index="/specialPushResults">推送结果</el-menu-item>-->
        <!--      </el-menu>-->
      </div>
      <div class="w center_all">
        <div class="center_2">
          <ul class="crumbs clearfix">
            <li>邮件推送</li>
            <li>-></li>
            <li>推送结果</li>
          </ul>
          <div class="conter_3 mb-3">
            <div class="list_btn" @click="isShowMore = !isShowMore" v-if="taskTableData.length > 1">任务列表</div>
            <div>
              <el-table
                ref="orderTable"
                class="tabHov"
                :data="taskTableDataOne"
                :style="isShowMore ? 'height:auto' : 'height:80px'"
                stripe
                style="width: 100%"
                highlight-current-row
                @current-change="handleCurrentChange"
              >
                <el-table-column type="index" width="60px" align="center">
                  <template slot-scope="scope">
                    <el-radio v-model="radio" class="radio" :label="scope.row.id" @change.native="getCurrentRow(scope.row.id)"><span></span></el-radio>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true" prop="topic" label="推送标题" width="200" align="left">
                  <template slot-scope="scope">
                    <span class="wcolor">{{ scope.row.topic }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="type" label="推送类型" align="center">
                  <template slot-scope="scope">
                    <span>{{ pushTypeObj[scope.row.type] }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="status" label="推送状态" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status === 3" class="wcolor1">{{ pushStatusObj[scope.row.status] }}</span>
                    <span v-else class="wcolor2">{{ pushStatusObj[scope.row.status] }}</span>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true" width="110" prop="pushTime" label="推送时间" align="center" />
                <el-table-column prop="operatorName" label="推送人" align="center" />
                <el-table-column prop="pushAuthorCount" label="推送范围" align="center" />
                <el-table-column prop="emailOPenCount" label="打开邮件次数" align="center" />
                <el-table-column prop="readCount" label="打开链接次数" align="center" />
              </el-table>
            </div>
          </div>
          <!-- 推送详情 -->
          <ul class="ul_0 mb-30">
            <li class="li_1">
              <p class="cole_c5463" v-text="allTaskInfo.totalCount"></p>
              <p>邮件推送共推送用户</p>
            </li>
            <li class="li_2">
              <p class="col_0ba9e1" v-text="allTaskInfo.totalOpenCount"></p>
              <p>打开邮件用户数量</p>
            </li>
            <li class="li_3">
              <p class="col_2db897" v-text="allTaskInfo.totalReadCount"></p>
              <p>点击链接用户数量</p>
            </li>
          </ul>
          <div class="conter_4 mb-3">
            <ul class="ul_1">
              <li>
                <p>推送主题：</p>
                <span v-text="taskInfo.topic || taskInfo.topic === 0 ? taskInfo.topic : '--'"></span>
              </li>
              <li>
                <p>推送用户：</p>
                <span v-text="taskInfo.pushSuccessCount || taskInfo.pushSuccessCount === 0 ? taskInfo.pushSuccessCount : '--'"></span>
              </li>
              <li>
                <p>用户打开量：</p>
                <span v-text="taskInfo.emailOPenCount || taskInfo.emailOPenCount === 0 ? taskInfo.emailOPenCount : '--'"></span>
              </li>
              <li>
                <p>推送时间：</p>
                <span v-text="taskInfo.pushTime || taskInfo.pushTime === 0 ? taskInfo.pushTime : '--'"></span>
              </li>
            </ul>
            <ul class="ul_2">
              <li>
                <p>邮件数量：</p>
                <span v-text="taskInfo.pushAuthorCount || taskInfo.pushAuthorCount === 0 ? taskInfo.pushAuthorCount : '--'"></span>
              </li>
              <li>
                <p>总打开数：</p>
                <span v-text="taskInfo.emailOPenCount || taskInfo.emailOPenCount === 0 ? taskInfo.emailOPenCount : '--'"></span>
              </li>
              <li>
                <p>首次打开时间：</p>
                <span v-text="taskInfo.startOpenTime || taskInfo.startOpenTime === 0 ? taskInfo.startOpenTime : '--'"></span>
              </li>
              <li>
                <p>最后打开时间：</p>
                <span v-text="taskInfo.endOpenTime || taskInfo.endOpenTime === 0 ? taskInfo.endOpenTime : '--'"></span>
              </li>
            </ul>
            <ul class="ul_3">
              <li>
                <p>推送文章数量：</p>
                <span v-text="taskInfo.articleCount || taskInfo.articleCount === 0 ? taskInfo.articleCount : '--'"></span>
              </li>
              <li>
                <p>总点击数：</p>
                <span v-text="taskInfo.readCount || taskInfo.readCount === 0 ? taskInfo.readCount : '--'"></span>
              </li>
              <li>
                <p>首次点击时间：</p>
                <span v-text="taskInfo.startClickTime || taskInfo.startClickTime === 0 ? taskInfo.startClickTime : '--'"></span>
              </li>
              <li>
                <p>最后点击时间：</p>
                <span v-text="taskInfo.endClickTime || taskInfo.endClickTime === 0 ? taskInfo.endClickTime : '--'"></span>
              </li>
            </ul>
          </div>
          <!-- 传播路径分析 -->
          <div class="mb-20">
            <h2 class="center_title mb-20">
              传播路径分析
            </h2>
            <div class="map_table">
              <div id="myChart" :style="{ width: '620px', height: '470px' }" />
              <SimpleTable no-pagination chart-table max-height="450" :tableData="diffuseTableData" :columnData="diffuseColumn" />
              <div class="fr btn postionMap">
                <!--    地图切换      -->
                <el-radio-group v-model="maps" @change="mapSelect">
                  <el-radio-button label="海外" />
                  <el-radio-button label="中国" />
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="clearfix">
            <!-- 统计概览 -->
            <div class="mb-20 fl" style="width: 549px">
              <h2 class="center_title mb-20">打开及点击情况统计</h2>
              <div id="myChart2" :style="{ width: '100%', height: '420px', background: '#f5f7fd', padding: '20px 0' }" v-if="haveTaskId" />
            </div>
            <!-- 打开及点击率  折线统计图-->
            <div class="mb-20 postion fr btn" style="width: 549px;padding-top: 50px" v-if="haveTaskId">
              <el-radio-group v-model="chooseChart3Time" class="chooseChart3Time">
                <el-radio-button v-for="item in timeOptions" :key="item.key" :label="item.key">{{ item.value }}</el-radio-button>
              </el-radio-group>
              <div id="myChart3" :style="{ width: '100%', height: '420px', background: '#f5f7fd', padding: '20px 0' }" />
            </div>
            <div class="noData" v-else>暂无数据</div>
          </div>
          <!-- 打开及点击情况统计表格 -->
          <span v-if="!showTable" class="showTableTxt">打开及点击情况统计表格</span>
          <div class="mb-35 countTable" v-if="showTable">
            <el-table :data="countTableColumn" style="width: 100%" show-summary align="center">
              <el-table-column type="index" label="序号" width="90" align="center" />
              <el-table-column prop="date" label="日期" align="center" />
              <el-table-column label="邮件推送情况" align="center">
                <el-table-column prop="readIncrCount" label="文章点击情况" align="center" />
                <el-table-column prop="emailOPenIncrCount" label="邮件打开情况" align="center" />
              </el-table-column>
              <el-table-column label="用户访问情况" align="center" v-if="haveUserData">
                <el-table-column prop="emainOpenUserIncrCount" label="打开邮件用户" align="center" />
                <el-table-column prop="readUserIncrCount" label="点击文章用户" align="center" />
              </el-table-column>
            </el-table>
          </div>
          <div class="showTableBtn" v-if="showTable" @click="closeTableBtn">
            <a href="javascript:;" onclick="return false" class="toggleable"><i class="el-icon-d-arrow-left"></i></a>
          </div>
          <div class="closeTableBtn showTableBtn" v-if="!showTable" @click="showTableBtn">
            <a href="javascript:;" onclick="return false" class="toggleable"><i class="el-icon-d-arrow-left"></i></a>
          </div>
          <!--文章点击量排行-->
          <div class="mb-35 conter_3">
            <h2 class="center_title mb-20">文章点击量排行</h2>
            <SimpleTable
              :tableData="articleTableData"
              :columnData="articleColumn"
              :initObj="articlePageObj"
              @onChangePageData="
                (pageObj) => {
                  getArticleListData(pageObj)
                }
              "
              @onClickTitle="handleOpenUrl"
            />
          </div>
          <!--学者活跃度排行-->
          <div class="mb-20 conter_3">
            <h2 class="center_title mb-20">学者活跃度排行</h2>
            <SimpleTable :tableData="authorTableData" :columnData="authorColumn" noPagination />
          </div>
        </div>
      </div>
      <div class="sp_footer">
        <span>北京北大方正电子有限公司提供技术支持</span>
      </div>
    </div>
  </div>
</template>
<script>
import SimpleTable from '@/components/SimpleTable'
import { GetPushTaskList, GetTaskInfo, GetArticleClickList, GetAuthorRankList, GetDiffusePathList, GetStatisticsByDay, GetAllTaskInfo, Verify } from '@/api/publishResults'
import { geoData, geoWorldData, nameMap } from '@/utils'
import { GetMagDetail } from '@/api/detail'
import { mapGetters } from 'vuex'
import china from '../../../public/js/china.js'
import world from 'echarts/map/js/world.js'
export default {
  name: 'PushResults',
  components: {
    SimpleTable
  },
  data() {
    return {
      // 当前选中的任务Id
      chooseTaskId: '',
      // 推送任务列表分页信息
      taskPageObj: {
        page: 1,
        size: 12
      },
      taskTableData: [], // 推送任务列表
      taskTableDataOne: [],
      pushStatusObj: {
        0: '待发送',
        1: '推送中',
        2: '已试推送',
        3: '已推送'
      },
      pushTypeObj: {
        1: '专题',
        2: '整期',
        3: '自助推送'
      },
      taskInfo: {},
      currentRow: [],
      radio: 0,
      radio1: '打开', // 打开及点击率 默认选择
      Chart3Data: [820, 932, 901, 934, 1290, 1330, 1320, 820, 932, 901], // 打开及点击率 默认选择的数据
      currentPage3: 1,
      total3: 100,
      // 文章点击量排行列表
      articleTableData: [],
      articleColumn: [
        {
          title: '标题',
          prop: 'topic',
          special: true,
          width: '300px',
          notSortable: true,
          clickParam: 'url'
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'org',
          notSortable: true
        },
        {
          title: '年/卷/期',
          prop: 'pubYearStage',
          notSortable: true
        },
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true
        },
        {
          title: '点击量',
          prop: 'clickCount',
          notSortable: true
        }
      ],
      articlePageObj: {
        page: 1,
        size: 10
      },
      authorPageObj: {
        page: 1,
        size: 10
      },
      authorTableData: [],
      authorColumn: [
        {
          title: '姓名',
          prop: 'name',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'organization',
          notSortable: true
        },
        {
          title: '研究领域',
          prop: 'domain',
          notSortable: true
        },
        {
          title: '点击文章量',
          prop: 'readCount',
          notSortable: true
        },
        {
          title: '打开邮件次数',
          prop: 'openEmailCount',
          notSortable: true
        }
      ],
      diffuseTableData: [],
      diffuseColumn: [
        {
          title: '传播路径',
          prop: 'territory',
          notSortable: true
        },
        {
          title: '人数',
          prop: 'count',
          notSortable: true
        },
        {
          title: '占比',
          prop: 'percent'
        }
      ],
      allTaskInfo: {},
      maps: '中国', // 地图切换 默认选中项
      // 折线图近七天、近三十天选项的options
      timeOptions: [
        {
          key: 7,
          value: '推送后7日'
        },
        {
          key: 30,
          value: '推送后30日'
        }
      ],
      chooseChart3Time: 7, //折线图近七天选中项
      haveTaskId: false,
      countTableColumn: [], // 打开及点击率 表格数据
      publishRegion: '北京', // 起始地址
      publishRegionvalue: '', // 起始地址value
      haveUserData: '', // 是否有用户数据
      platMagId: '',
      platTenantId: '',
      magId: '',
      isClickDown: false,
      showTable: false,
      platMagName: '',
      isShowMore: false // 是否显示更多任务列表
    }
  },
  mounted() {
    if (window.location.href.indexOf('magId') != -1) {
      let id = window.location.href.split('magId=')[1]
      this.magId = id
      this.$prompt('请输入验证码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          const params = {
            magId: this.magId,
            captcha: value
          }
          Verify(params).then((res) => {
            if (res && res.data && res.data.status === 0) {
              const { data } = res.data
              if (data.status) {
                this.getPushTaskListData()
                this.getAllTaskInfoData()
                this.getMagDetail()
                this.$message({
                  type: 'success',
                  message: res.data.message
                })
              } else {
                this.$message.error('验证码错误')
              }
            } else {
              this.magId = ''
              this.$message.error(res.data.message)
            }
          })
        })
        .catch(() => {
          this.magId = ''
          this.$message({
            type: 'info',
            message: '取消输入'
          })
        })
    }
  },
  watch: {
    // magId() {
    //   this.getPushTaskListData()
    //   this.getAllTaskInfoData()
    //   this.getMagDetail()
    // },
    chooseChart3Time(nv) {
      this.getStatisticsData()
    }
  },
  methods: {
    // 去掉传播路径中地区的中国
    computedDiffuseTableData() {
      return this.diffuseTableData.map((it) => {
        if (it && it.territory && it.territory.indexOf('中国-') != -1) {
          it.territory = it.territory.split('中国-')[1]
        } else {
          it.territory = it.territory
        }
      })
    },
    // 展开打开及点击情况统计表格
    showTableBtn() {
      this.showTable = true
    },
    closeTableBtn() {
      this.showTable = false
    },
    clickDownList(item) {
      if (item) {
        this.isClickDown = false
      } else {
        this.isClickDown = true
      }
    },
    // 跳转页面
    handleOpenUrl(chooseItem, columnItem) {
      const { clickParam = '' } = columnItem
      if (clickParam && clickParam !== '' && chooseItem[clickParam] !== '') {
        window.open(chooseItem[clickParam])
      }
    },
    // 期刊详情查询
    getMagDetail() {
      const params = {
        id: this.magId
      }
      GetMagDetail(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (data.publishRegion) {
            if (data.publishRegion.indexOf('市') != -1 && data.publishRegion.indexOf('省') != -1) {
              this.publishRegion = data.publishRegion.split('市')[0].split('省')[1] || '北京'
            } else if (data.publishRegion.indexOf('省') != -1) {
              this.publishRegion = data.publishRegion.split('省')[0] || '北京'
            } else if (data.publishRegion.indexOf('市') != -1) {
              this.publishRegion = data.publishRegion.split('市')[0] || '北京'
            }
          } else {
            this.publishRegion = '北京'
          }
          this.platMagName = data.platMagName
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取所有任务用户行为信息
    async getAllTaskInfoData() {
      const params = {
        tenantId: this.platTenantId,
        publicationId: this.platMagId,
        magId: this.magId
      }
      GetAllTaskInfo(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.$set(this, 'allTaskInfo', data)
        }
      })
    },
    // 获取点击按天统计折线图数据
    async getStatisticsData() {
      const params = {
        taskId: this.chooseTaskId,
        lasFewDays: this.chooseChart3Time
      }
      GetStatisticsByDay(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.chart3(data.data)
          this.countTableColumn = data.data
          this.haveUserData = data.type
          // 有用户数据就是折线图，没有就是柱状图
          if (this.haveUserData) {
            this.lineChart2(data.data)
          } else {
            this.chart2(data.data)
          }
          this.getTaskInfoData()
        }
      })
    },
    // 获取传播路径分析列表
    async getDiffuseListData(pageParams = {}) {
      let type = this.maps === '海外' ? 2 : 1 // 1是国内 2是国外
      const { page = 1, size = 1000 } = pageParams
      const params = {
        page: page - 1,
        size,
        taskId: this.chooseTaskId,
        type: type
      }
      GetDiffusePathList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.diffuseTableData = data
          this.computedDiffuseTableData()
          this.initEchart(data)
        }
      })
    },
    // 获取作者活跃度排行
    async getAuthorListData(pageParams = {}) {
      const { page = 1, size = 20 } = pageParams
      const params = {
        page: page - 1,
        size,
        taskId: this.chooseTaskId
      }
      GetAuthorRankList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.authorPageObj = {
            page,
            size,
            total: totalElements
          }
          this.authorTableData = content
        }
      })
    },
    // 获取文章点击量排行
    async getArticleListData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        page: page - 1,
        size,
        taskId: this.chooseTaskId
      }
      GetArticleClickList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.articlePageObj = {
            page,
            size,
            total: totalElements
          }
          this.articleTableData = content
        }
      })
    },
    // 推送列表获取
    async getPushTaskListData(pageParams = {}) {
      const { page = 1, size = 12 } = pageParams
      const params = {
        page: page - 1,
        size,
        tenantId: this.platTenantId,
        publicationId: this.platMagId,
        magId: this.magId
      }
      GetPushTaskList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.taskPageObj = {
            page,
            size,
            total: totalElements
          }
          this.taskTableData = content
          if (!this.taskTableDataOne[0]) {
            this.taskTableDataOne.push(content[0])
          } else {
            this.taskTableDataOne[0] = content[0]
          }
          if (this.taskTableData && this.taskTableData.length && this.taskTableData.length > 0) {
            const { id = '' } = this.taskTableData[0]
            this.haveTaskId = true
            // 选中的ID为第一个数据的Id
            this.chooseTaskId = id
            // 初始化选中的数据为第一行
            this.handleCurrentChange(this.taskTableData[0])
            this.getStatisticsData()
            this.getDiffuseListData()
            this.getArticleListData()
            this.getAuthorListData()
          } else {
            this.haveTaskId = false
            this.chooseTaskId = ''
            //taskId不存在清空列表数据
            this.$set(this, 'taskInfo', '')
            this.diffuseTableData = []
            this.initEchart(this.diffuseTableData)
            this.articleTableData = []
            this.authorTableData = []
          }
        }
      })
    },
    // 获取选中的task的详情数据
    getTaskInfoData() {
      const params = {
        taskId: this.chooseTaskId
      }
      GetTaskInfo(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data = {} } = res.data
          this.$set(this, 'taskInfo', data)
          if (this.haveUserData) {
            this.lineChart2(this.countTableColumn)
          } else {
            const chartData = [data.onlyEmailOPenCount, data.emailOPenCount, data.onlyReadCount, data.readCount]
            this.chart2(chartData)
          }
        }
      })
    },
    // 推送结果选中行改变
    handleCurrentChange(val) {
      // 选中行
      if (val) {
        // 设置radio选中
        this.currentRow = val
        const { id } = val
        this.chooseTaskId = id
        this.radio = id
        this.getDiffuseListData()
        this.getArticleListData()
        this.getAuthorListData()
        this.getStatisticsData()
        this.taskTableDataOne[0] = val
        this.isClickDown = false
      }
    },
    handleSizeChange(val) {
      // 打开及点击率 分页 每页条数
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange1(val) {
      // 打开及点击率 分页 当前页
      console.log(`当前页: ${val}`)
    },
    // 设置单选框选择状态
    getCurrentRow(chooseId) {
      this.radio = chooseId
    },
    // 地图切换
    mapSelect() {
      this.getDiffuseListData()
      this.initEchart(this.diffuseTableData)
    },
    // 地图
    initEchart(data = []) {
      let mapData = ''
      let geoCoordMap = null
      if (this.maps === '海外') {
        // 坐标数据
        mapData = 'world'
        geoCoordMap = geoWorldData
      } else {
        mapData = 'china'
        geoCoordMap = geoData
      }
      const myChartMap = this.$echarts.init(document.getElementById('myChart'))
      // 连线特效
      const planePath =
        'path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z'
      // 处理连线坐标
      const convertData = function(data) {
        const res = []
        for (let i = 0; i < data.length; i += 1) {
          const dataItem = data[i]
          const fromCoord = geoCoordMap[dataItem[0].name]
          const toCoord = geoCoordMap[dataItem[1].name]
          if (fromCoord && toCoord) {
            res.push({
              fromName: dataItem[0].name,
              toName: dataItem[1].name,
              coords: [fromCoord, toCoord]
            })
          }
        }
        return res
      }
      // 数据
      const BJData = data.map((item) => {
        let territoryStr = ''
        if (item.territory) {
          let str = item.territory
          if (this.maps === '海外' && !str.includes('-')) {
            territoryStr = str
          } else {
            let index = str.lastIndexOf('-')
            territoryStr = str.substring(index + 1, str.length - 1)
          }
        }
        this.publishRegionvalue = territoryStr == this.publishRegion ? item.count : 95
        const tempVal = [{ name: this.publishRegion }]
        const targetVal = { name: territoryStr, value: item.count }
        tempVal.push(targetVal)
        return tempVal
      })
      // const BJData = [
      // [{ name: '北京' }, { name: '北京', value: 95 }]
      // ].concat(targetData)
      const color = ['#a6c84c', '#ffa022', '#46bee9']
      const series = []
      if (this.maps === '海外') {
        BJData.push([{ name: this.publishRegion }, { name: this.publishRegion, value: this.publishRegionvalue }])
      }
      const array = [[this.publishRegion, BJData]]
      array.forEach(function(item, i) {
        series.push(
          {
            type: 'lines',
            zlevel: 1,
            effect: {
              show: true,
              period: 6,
              trailLength: 0.7,
              color: '#fff',
              symbolSize: 3
            },
            lineStyle: {
              normal: {
                color: color[i],
                width: 0,
                curveness: 0.2
              }
            },
            data: convertData(item[1])
          },
          {
            type: 'lines',
            zlevel: 2,
            symbol: ['none', 'arrow'],
            symbolSize: 10,
            effect: {
              show: false,
              period: 6,
              trailLength: 0,
              symbol: planePath,
              symbolSize: 15
            },
            lineStyle: {
              color: color[i],
              width: 1,
              opacity: 0.6,
              curveness: 0.2
            },
            data: convertData(item[1])
          },
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 3,
            rippleEffect: {
              brushType: 'stroke'
            },
            label: {
              show: true,
              position: 'right',
              formatter: '{b}'
            },
            symbolSize: function(val) {
              return val[2] / 100
            },
            itemStyle: {
              color: color[i]
            },
            data: item[1].map(function(dataItem) {
              return {
                name: dataItem[1].name,
                value: geoCoordMap[dataItem[1].name] ? geoCoordMap[dataItem[1].name].concat([dataItem[1].value]) : ''
              }
            })
          }
        )
      })
      myChartMap.setOption({
        // 新建一个地理坐标系 geo ，
        geo: {
          map: mapData, // 地图类型为中国地图,要是海外那就是world,要是省市区：例如beijing、shanghai
          zoom: 1.2,
          itemStyle: {
            // 定义样式
            normal: {
              // 普通状态下的样式
              areaColor: '#6699CC',
              borderColor: '#fff'
            },
            emphasis: {
              // 高亮状态下的样式
              areaColor: '#e9fbf1'
            }
          }
        },
        nameMap: nameMap, // 中英文名字转换
        // hover显示目标数据
        tooltip: {
          trigger: 'item',
          formatter: function(params) {
            let res
            if (params.data.value) {
              res = '<p>人数' + params.data.value[2] + '</p>'
            }

            return res
          },
          // tooltip的trigger的值可以有'item'、'axis'。
          // 'item':数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。
          // 'axis':坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
          textStyle: {
            align: 'left'
          }
        },
        toolbox: {
          show: true,
          feature: {
            myFull: {
              show: true,
              title: '全屏查看',
              icon:
                'path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891',
              onclick: (e) => {
                const element = document.getElementById('myChart')
                const myEcharts = this.$echarts.init(element)
                window.onresize = myEcharts.resize
                if (element.requestFullScreen) {
                  // HTML W3C 提议
                  element.requestFullScreen()
                } else if (element.msRequestFullscreen) {
                  // IE11
                  element.msRequestFullScreen()
                } else if (element.webkitRequestFullScreen) {
                  // Webkit (works in Safari5.1 and Chrome 15)
                  element.webkitRequestFullScreen()
                } else if (element.mozRequestFullScreen) {
                  // Firefox (works in nightly)
                  element.mozRequestFullScreen()
                }
                // 退出全屏
                if (element.requestFullScreen) {
                  document.exitFullscreen()
                } else if (element.msRequestFullScreen) {
                  document.msExitFullscreen()
                } else if (element.webkitRequestFullScreen) {
                  document.webkitCancelFullScreen()
                } else if (element.mozRequestFullScreen) {
                  document.mozCancelFullScreen()
                }
              }
            }
          }
        },
        // 图表背景色
        backgroundColor: '#2644ca',
        // 标志颜色
        color: 'red',
        // 新建散点图series
        series: series,
        // // 添加视觉映射组件
        visualMap: {
          type: 'continuous', // 连续型
          min: 0, // 值域最小值，必须参数
          max: 100, // 值域最大值，必须参数
          calculable: true, // 是否启用值域漫游
          inRange: {
            color: ['yellow', 'red']
            // 指定数值从低到高时的颜色变化
          },
          itemWidth: 10,
          itemHeight: 92,
          textStyle: {
            color: '#fff' // 值域控件的文本颜色
          }
        }
      })
    },
    // 以前柱状图
    chart2(chartData = []) {
      const myChart = this.$echarts.init(document.getElementById('myChart2'))
      myChart.setOption({
        // color: ['#37a2da','#32c5e9','#67e0e3','#9fe6b8','#ffdb5c','#ff9f7f'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['唯一打开数', '总打开数', '唯一点击数', '总点击数'], // '总发送数', '发送成功数',
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: '30',
            data: chartData, // 1500, 780,
            itemStyle: {
              // 通常情况下：

              normal: {
                // 每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
                color: function(params) {
                  const colorList = ['#37a2da', '#32c5e9', '#67e0e3', '#9fe6b8', '#ffdb5c', '#ff9f7f']
                  return colorList[params.dataIndex % colorList.length]
                }
              }
            }
          }
        ]
      })
    },
    // 打开 or 点击 数据切换
    // myChart3Data (val) {
    //     if (val === '打开') {
    //         this.Chart3Data = [820, 932, 901, 934, 1290, 1330, 1320, 820, 932, 901]
    //     } else {
    //         this.Chart3Data = [1320, 820, 932, 901, 820, 932, 901, 934, 1290, 1330]
    //     }
    //     this.chart3()
    // },
    // 现在左边折线图
    lineChart2(lineData = []) {
      const myChart = this.$echarts.init(document.getElementById('myChart2'))
      const leftTimeArr = []
      const dailyOpenEmailUser = []
      const dailyClickArticleUser = []
      const allOpenEmailUser = []
      const allClickArticleUser = []
      var leftFanalTimeArr = []
      let _this = this
      // lineData.reverse()
      for (let i = 0; i < lineData.length; i += 1) {
        leftTimeArr.push(lineData[i].date)
        dailyOpenEmailUser.push(lineData[i].emainOpenUserIncrCount || lineData[i].emainOpenUserIncrCount === 0 ? lineData[i].emainOpenUserIncrCount : '-')
        dailyClickArticleUser.push(lineData[i].readUserIncrCount || lineData[i].readUserIncrCount === 0 ? lineData[i].readUserIncrCount : '-')
        allOpenEmailUser.push(lineData[i].emailOPenUseerCount || lineData[i].emailOPenUseerCount === 0 ? lineData[i].emailOPenUseerCount : '-')
        allClickArticleUser.push(lineData[i].readUserCount || lineData[i].readUserCount === 0 ? lineData[i].readUserCount : '-')
      }
      leftFanalTimeArr = [leftTimeArr[0], leftTimeArr[leftTimeArr.length - 1]]
      myChart.setOption({
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: leftTimeArr,
          axisLabel: {
            formatter: function(value, index) {
              if (_this.chooseChart3Time == 7) {
                return value
              } else {
                if (index == 0 || index == leftTimeArr.length / 2 || index == leftTimeArr.length - 1) {
                  return value
                } else {
                  return null
                }
              }
            },
            interval: 0,
            rotate: 60
          }
        },
        yAxis: {
          type: 'value',
          minInterval: 1
        },
        tooltip: {
          trigger: 'axis',
          formatter: function(paramData) {
            let domString = '<ul style="text-align: left;font-size: 12px">'
            for (let i = 0; i < paramData.length; i += 1) {
              domString += `
                <li>${paramData[i].seriesName}:${paramData[i].value}</li>
              `
            }
            domString += '</ul>'
            return domString
          }
        },
        legend: {
          y: 'top',
          x: 'center',
          data: ['日打开邮件用户', '日点击文章用户', '总打开邮件用户', '总点击文章用户']
        },
        series: [
          {
            name: '日打开邮件用户', // emainOpenUserIncrCount
            type: 'line',
            data: dailyOpenEmailUser
          },
          {
            name: '日点击文章用户', // readUserIncrCount
            type: 'line',
            data: dailyClickArticleUser
          },
          {
            name: '总打开邮件用户', //  emailOPenUseerCount
            type: 'line',
            data: allOpenEmailUser
          },
          {
            name: '总点击文章用户', //  readUserCount
            type: 'line',
            data: allClickArticleUser
          }
        ]
      })
    },
    // 折线
    chart3(lineData = []) {
      const myChart = this.$echarts.init(document.getElementById('myChart3'))
      const timeArr = []
      const articleArr = []
      const mailArr = []
      const dailyArticle = []
      const dailyEmail = []
      var fanalTimeArr = []
      let _this = this
      // lineData.reverse()
      for (let i = 0; i < lineData.length; i += 1) {
        timeArr.push(lineData[i].date)
        articleArr.push(lineData[i].readCount || lineData[i].readCount === 0 ? lineData[i].readCount : '-')
        mailArr.push(lineData[i].emailOPenCount || lineData[i].emailOPenCount === 0 ? lineData[i].emailOPenCount : '-')
        dailyArticle.push(lineData[i].readIncrCount || lineData[i].readIncrCount === 0 ? lineData[i].readIncrCount : '-')
        dailyEmail.push(lineData[i].emailOPenIncrCount || lineData[i].emailOPenIncrCount === 0 ? lineData[i].emailOPenIncrCount : '-')
      }
      fanalTimeArr = [timeArr[0], timeArr[timeArr.length - 1]]
      myChart.setOption({
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: timeArr,
          axisLabel: {
            formatter: function(value, index) {
              if (_this.chooseChart3Time == 7) {
                return value
              } else {
                if (index == 0 || index == timeArr.length / 2 || index == timeArr.length - 1) {
                  return value
                } else {
                  return null
                }
              }
            },
            interval: 0,
            rotate: 60
          }
        },
        yAxis: {
          type: 'value',
          minInterval: 1
        },
        tooltip: {
          trigger: 'axis',
          formatter: function(paramData) {
            let domString = '<ul style="text-align: left;font-size: 12px">'
            for (let i = 0; i < paramData.length; i += 1) {
              domString += `
                <li>${paramData[i].seriesName}:${paramData[i].value}</li>
              `
            }
            domString += '</ul>'
            return domString
          }
        },
        legend: {
          y: 'top',
          x: 'center',
          data: ['每日打开数', '每日点击数', '累计打开数', '累计点击数']
        },
        series: [
          {
            name: '每日打开数',
            type: 'line',
            data: dailyEmail
          },
          {
            name: '每日点击数',
            type: 'line',
            data: dailyArticle
          },
          {
            name: '累计打开数',
            type: 'line',
            data: mailArr
          },
          {
            name: '累计点击数',
            type: 'line',
            data: articleArr
          }
        ]
      })
    }
  }
}
</script>
<style scoped lang="scss">
.crumbs {
  padding-top: 18px;
}
.conter_4 {
  display: flex;
}
.conter_4 > ul {
  box-sizing: border-box;
  border-right: 1px solid #eaeaea;
}
.conter_4 > ul > li {
  font-size: 14px;
  color: #999;
  text-align: left;
  line-height: 30px;
  display: flex;
}
.conter_4 > ul > li > p {
  width: 140px;
}
.conter_4 > ul > li > span {
  color: #666;
}
.conter_4 .ul_1 > li > span {
  color: #2644ca;
  display: inline-block;
  text-align: left;
  width: 100%;
}
.ul_1 {
  width: 400px;
}
.conter_4 .ul_1 > li > p {
  width: 140px;
}
.ul_2 {
  width: 380px;
  padding-left: 30px;
}
.conter_4 > ul.ul_3 {
  flex: 1;
  padding-left: 30px;
  border: none;
}
.center_table {
  position: absolute;
  top: 0px;
  right: 0;
  padding: 10px;
  width: 510px;
  height: 450px;
}
.map_table {
  position: relative;
  background: #265cca;
}
.postion {
  position: relative;
}
.setChartData {
  position: absolute;
  top: 70px;
  left: 50%;
  margin-left: -78px;
  z-index: 100;
}
.ul_0 {
  display: flex;
  display: none;
  font-size: 14px;
  color: #999;
  text-align: left;
  line-height: 30px;
  background: #f5f7fd;
  /*border: 1px solid #ebeef5;*/
  padding: 14px 0;
}
.li_1 {
  width: 379px;
}
.li_2 {
  width: 379px;
}
.li_3 {
  width: 379px;
}
.ul_0 li p {
  line-height: 24px;
  text-align: center;
}
.ul_0 li p.cole_c5463 {
  line-height: 28px;
  font-size: 22px;
  color: #ec5463;
}
.ul_0 li p.col_0ba9e1 {
  line-height: 28px;
  font-size: 22px;
  color: #0ba9e1;
}
.ul_0 li p.col_2db897 {
  line-height: 28px;
  font-size: 22px;
  color: #2db897;
}
.postionMap {
  position: absolute;
  top: 12px;
  left: 12px;
}
.btn.postionMap .el-radio-button {
  top: 0;
  margin-right: 10px;
}
.chooseChart3Time {
  position: absolute;
  top: 15px;
  right: 0;
}
.chooseChart3Time label:nth-child(1) {
  margin-right: 20px;
}
.clearfix .noData {
  display: inline-block;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #edeff7;
  color: #909399;
}
/deep/ .countTable {
  .el-table thead.is-group th,
  .el-table__header-wrapper tbody td,
  .el-table th.is-leaf,
  .el-table__footer-wrapper tbody td {
    background-color: #f1f2f4 !important;
    border-right: 1px solid #e1e3e4;
    border-bottom: 1px solid #e1e3e4;
  }
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
    border-right: 1px solid #e1e3e4;
  }
  .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid #e1e3e4;
  }
  .el-table__footer-wrapper tbody td {
    border-top: 1px solid #e1e3e4;
    border-bottom: 1px solid #e1e3e4;
  }
  /deep/ .countTable {
    .el-table thead.is-group th,
    .el-table__header-wrapper tbody td,
    .el-table th.is-leaf,
    .el-table__footer-wrapper tbody td {
      background-color: #f1f2f4 !important;
      border-right: 1px solid #e1e3e4;
      border-bottom: 1px solid #e1e3e4;
    }
    .countTable .el-table__footer-wrapper {
      display: none;
    }
    .el-table--border td,
    .el-table--border th,
    .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
      border-right: 1px solid #e1e3e4;
    }
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 1px solid #e1e3e4;
    }
    .el-table__footer-wrapper tbody td {
      border-top: 1px solid #e1e3e4;
      border-bottom: 1px solid #e1e3e4;
    }
  }
}

.specialPushResults {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #fff;
  height: 60px;
  padding-top: 35px;
  box-sizing: border-box;
  margin-top: 89px;

  .el-divider--horizontal {
    width: -moz-calc(100% - 60px);
    width: -webkit-calc(100% - 60px);
    width: -o-calc(100% - 60px);
    width: calc(100% - 60px);
    margin: 17px auto;
  }
  .el-table th,
  .el-table tr {
    background: #f1f2f4;
  }
  .el-divider {
    background-color: #dfdfdf;
  }
  div {
    font-size: 26px;
    line-height: 36px;
    color: #2644ca;
    font-weight: bold;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: #ccc;
  }
}
</style>
<style scoped>
#app .TOP {
  display: none;
}
#app .w > .el-menu--horizontal {
  display: none;
}
.home_title {
  background: transparent !important;
}
#app .TOP_bei {
  height: 87px !important;
}
#app .header {
  height: auto;
  padding-top: 30px;
}
#app .center_all {
  margin-bottom: 20px;
  margin-top: -10px;
}
</style>
