<!-- skType 社科版 -->
<template>
  <div class="skType">
    <!--
      logo:微网刊logo设置 mag:期刊介绍封面设置 codes:二维码设置
      skCover: 社科顶部左边封面 carousel：轮播图设置（本期样式设置时显示）
      weChatShare： 微信分享设置
     -->
    <el-form ref="ruleForm" :model="ruleForm" label-width="110px">
      <logo v-if="showLogo" :ruleForm="ruleForm" :logoImg="logoImg"></logo>
      <mag v-if="showMag" :ruleForm="ruleForm" :magImg="magImg" :maxlength="12" showResetBtn moduleType="sk"></mag>
      <!-- <skCover v-if="showSkCover" :ruleForm="ruleForm"></skCover> -->
      <codes v-if="showCodes" :ruleForm="ruleForm" :codeImg="codeImg"></codes>
      <carousel v-if="showCarousel" :ruleForm="ruleForm" :carouselImg="carouselImg" :carouselTableData="carouselTableData"></carousel>
      <weChatShare v-if="showWeChatShare" :ruleForm="ruleForm"></weChatShare>
    </el-form>
  </div>
</template>

<script>
import logo from './module/logo'
import mag from './module/mag'
// import skCover from './module/skCover'
import codes from './module/codes'
import carousel from './module/carousel.vue'
import weChatShare from './module/weChatShare.vue'
export default {
  name: 'skType',
  data() {
    return {
      logoImg: require('@/assets/img/template_img/sk/module0.png'),
      codeImg: require('@/assets/img/template_img/sk/module7.png'),
      magImg: require('@/assets/img/template_img/sk/module6.png'),
      carouselImg: require('@/assets/img/template_img/sk/module1.png')
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    carouselTableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    showLogo: {
      type: Boolean,
      default: false
    },
    showMag: {
      type: Boolean,
      default: false
    },
    showSkCover: {
      type: Boolean,
      default: false
    },
    showCodes: {
      type: Boolean,
      default: false
    },
    showCarousel: {
      type: Boolean,
      default: false
    },
    showWeChatShare: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    magId() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
      })
    }
  },
  components: {
    logo,
    mag,
    // skCover,
    codes,
    carousel,
    weChatShare
  },
  computed: {},
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
