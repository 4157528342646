<!-- hnclType -->
<template>
  <div class="hnclType">
    <!-- logo:微网刊logo设置 subject:顶部介绍封面设置 enInfo:含能材料每期英文信息设置
      mag:期刊介绍封面设置 weChatShare: 微信分享设置 -->
    <el-form ref="ruleForm" :model="ruleForm" label-width="110px">
      <logo v-if="showLogo" :ruleForm="ruleForm" :logoImg="logoImg"></logo>
      <subject v-if="showSubject" :ruleForm="ruleForm" :subjectImg="subjectImg" :maxlength="30"></subject>
      <enInfo v-if="showEnfo" :ruleForm="ruleForm" :enInfoImg="enInfoImg"></enInfo>
      <browseBackIssues v-if="showBrowseBackIssues" :ruleForm="ruleForm" :browseBackIssuesImg="browseBackIssuesImg"></browseBackIssues>
      <codes v-if="showCodes" :ruleForm="ruleForm" :codeImg="codeImg"></codes>
      <weChatShare :ruleForm="ruleForm"></weChatShare>
    </el-form>
  </div>
</template>

<script>
import logo from './module/logo'
import subject from './module/subject'
import enInfo from './module/enInfo'
import codes from './module/codes'
import weChatShare from './module/weChatShare.vue'
import browseBackIssues from './module/browseBackIssues.vue'
export default {
  name: 'hnclType',
  data() {
    return {
      logoImg: require('@/assets/img/template_img/hncl/module0.png'),
      codeImg: require('@/assets/img/template_img/hncl/module7.png'),
      subjectImg: require('@/assets/img/template_img/hncl/module9.png'),
      enInfoImg: require('@/assets/img/template_img/hncl/module10.png'),
      browseBackIssuesImg: require('@/assets/img/template_img/hncl/module11.png')
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showLogo: {
      type: Boolean,
      default: false
    },
    showSubject: {
      type: Boolean,
      default: false
    },
    showEnfo: {
      type: Boolean,
      default: false
    },
    showBrowseBackIssues: {
      type: Boolean,
      default: false
    },
    showCodes: {
      type: Boolean,
      default: false
    }
  },
  components: {
    logo,
    codes,
    subject,
    weChatShare,
    enInfo,
    browseBackIssues
  },
  watch: {
    magId() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
      })
    }
  },
  computed: {},
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
