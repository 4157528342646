<!-- informationSetting -->
<template>
  <div class="w center_all informationSetting">
    <div class="center_2">
      <ul class="crumbs clearfix">
        <li>资讯聚合</li>
        <li>-></li>
        <li>配置管理</li>
      </ul>
      <div class="informationSetting-content">
        <div class="filter-condition">
          <div class="part">
            <div class="text">信源配置：</div>
            <el-button class="btn fl" size="small" round icon="el-icon-upload" @click="importVisible = true">信源导入</el-button>
          </div>
          <div class="part">
            <div class="text">信源检索：</div>
            <el-input placeholder="请输入内容" v-model="searchVal" class="input-with-select">
              <el-select v-model="selectVal" slot="prepend" placeholder="请选择">
                <el-option v-for="(item, index) in selectList" :key="index" :label="item.label" :value="item.value" />
              </el-select>
              <el-button slot="append" icon="el-icon-search" @click="getTableData"></el-button>
            </el-input>
          </div>
          <div class="part">
            <div class="text">采集状态：</div>
            <el-select v-model="statusVal" slot="prepend" placeholder="请选择" @change="getTableData">
              <el-option v-for="(item, index) in statusList" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </div>
        </div>
        <div class="filter-table">
          <el-button class="btn fl" size="small" round icon="el-icon-delete" :disabled="btnDisabled" @click="deleteItems">删除</el-button>
          <el-button class="btn fl" size="small" round icon="el-icon-position" :disabled="btnDisabled" @click="importItems">导出</el-button>
          <span class="fr" style="margin: 30px 0px 10px 0">已选中{{ multipleSelection.length }}条数据</span>
          <SimpleTable
            class="table-list"
            ref="tableList"
            noSerial
            hasSelect
            :tableData="tableData"
            :columnData="tableColumn"
            :initObj="tablePageObj"
            @onRowClick="handleRowClick"
            @onChangeSelection="handleSelectionChange"
            @onClickTitle="handleOpenUrl"
            @onChangePageData="
              (pageObj) => {
                getTableData(pageObj)
              }
            "
            @onChangeSort="
              (prop, order) => {
                handleChangeSort(prop, order)
              }
            "
          />
        </div>
      </div>
      <!-- 信源导入弹框 -->
      <el-dialog title="导入资讯信源" custom-class="import-dialog" align="left" :close-on-click-modal="false" :visible.sync="importVisible" width="560px" :show-close="true">
        <div class="tip">
          <p>导入说明：</p>
          <p>1.仅支持xls,xlsx格式的文件，请<span @click="handleDownloadSample">下载模板</span>文件；</p>
          <p>2.其中站点名，频道名，url为必填项，请严格按照模板文件填写，否则系统无法识别。</p>
        </div>
        <div class="upload-text">上传文件：</div>
        <el-upload
          ref="uploadMsg"
          name="file"
          :auto-upload="true"
          :before-upload="beforeUploadFile"
          :on-success="handleExportSuccess"
          :data="exportForm"
          :file-list="fileList"
          class="upload-export"
          :action="ImportInformationTask"
          accept=".xls, .xlsx"
        >
          <el-button>选择文件</el-button>
        </el-upload>
        <span slot="footer" class="dialog-footer">
          <el-button @click="importVisible = false">取 消</el-button>
          <el-button type="primary" @click="importVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SimpleTable from '@/components/SimpleTable'
import { ImportTemplateDownload, ImportInformationTask, GetListTask, BatchDelete, ExportTask } from '@/api/InformationAggregation'
export default {
  name: 'informationSetting',
  data() {
    return {
      ImportInformationTask,
      selectList: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 'siteName',
          label: '站点名'
        },
        {
          value: 'channelName',
          label: '频道名'
        }
      ],
      searchVal: '',
      selectVal: '',
      exportForm: {
        magId: ''
      },
      statusList: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '1',
          label: '已采集'
        },
        {
          value: '0',
          label: '未采集'
        }
      ],
      statusVal: '',
      tableData: [],
      tableColumn: [
        {
          title: '站点名',
          prop: 'siteName',
          notSortable: true,
          width: '140px'
        },
        {
          title: '频道名',
          prop: 'channelName',
          notSortable: true,
          special: true,
          width: '220px'
        },
        {
          title: 'URL',
          prop: 'url',
          notSortable: true
        },
        {
          title: '采集状态',
          prop: 'collectStatusText',
          notSortable: true,
          width: '80px'
        },
        {
          title: '入库时间',
          prop: 'sysCreateTime',
          width: '120px'
        }
      ],
      tablePageObj: {
        page: 1,
        size: 10,
        sort: 'sysCreateTime,desc'
      },
      importVisible: false,
      fileList: [],
      multipleSelection: []
    }
  },
  props: {},
  components: { SimpleTable },
  computed: {
    ...mapGetters({
      magId: 'magId'
    }),
    btnDisabled() {
      return this.multipleSelection.length === 0
    }
  },
  mounted() {
    this.getTableData()
  },
  watch: {
    magId() {
      this.getTableData()
    }
  },
  methods: {
    getTableData(pageParams = {}) {
      let { page = 1, size = 10 } = pageParams
      let params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort: this.tablePageObj.sort,
        field: this.selectVal,
        collectStatus: this.statusVal,
        value: this.searchVal
      }
      GetListTask(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.tablePageObj = {
            ...this.tablePageObj,
            page,
            size,
            total: data.totalElements
          }
          if (data && data.content.length > 0) {
            this.tableData = data.content
            this.tableData.map((item) => {
              item.collectStatusText = item.collectStatus ? (item.collectStatus === 1 ? '已采集' : '未采集') : '--'
            })
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    handleChangeSort(prop, order) {
      let sortValue = ''
      if (order && order !== '') {
        if (order === 'ascending') {
          sortValue = prop + ',asc'
        } else {
          sortValue = prop + ',desc'
        }
      }
      this.tablePageObj.sort = sortValue
      this.getTableData()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 上传文件
    beforeUploadFile(file) {
      this.exportForm.magId = this.magId
      let fileType = ''
      let arr = ['xls', 'xlsx']
      let filePath = file.name
      let fileNameLength = file.name.lastIndexOf('.')
      if (fileNameLength != -1) {
        fileType = filePath.substring(fileNameLength + 1, filePath.length).toLowerCase()
      } else {
        fileType = ''
      }
      if (!arr.includes(fileType)) {
        this.$message({
          message: '请上传' + arr + '类型文件！',
          duration: 1500,
          type: 'error'
        })
        return false
      }
    },
    // 上传文件成功
    handleExportSuccess(res, file, fileList) {
      if (fileList.length > 1) {
        fileList.shift()
      }
      this.fileList = fileList
      this.$refs.uploadMsg.clearFiles() // 清除上次上传记录
      if (res && res.status === 0) {
        this.$message.success('上传成功')
        this.getTableData()
      } else {
        const { message = '' } = res
        fileList.pop()
        this.$message.error(message)
      }
    },
    handleRowClick() {},
    // 下载模板
    handleDownloadSample() {
      window.open(`${ImportTemplateDownload}`, '_self')
    },
    deleteItems() {
      this.$confirm('此操作将永久删除已选中数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const delIds = this.multipleSelection.map((item) => {
            return item.id
          })
          BatchDelete({
            ids: delIds.join(',')
          }).then((res) => {
            if (res && res.data && res.data.status === 0) {
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.$refs.tableList.clearSelection()
              this.getTableData()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    importItems() {
      this.$confirm('确定导出已选中数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs.tableList.clearSelection()
          const exportIds = this.multipleSelection.map((item) => {
            return item.id
          })
          window.open(`${ExportTask}?ids=${exportIds.join(',')}`, '_self')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消导出'
          })
        })
    },
    handleOpenUrl(item) {
      const { url } = item
      if (url) {
        window.open(url)
      }
    }
  }
}
</script>
<style lang="scss">
.informationSetting {
  .input-with-select {
    width: 400px;
    .el-input-group__prepend {
      width: 60px;
    }
  }
  .import-dialog {
    .tip {
      line-height: 24px;
      span {
        color: #265cca;
        cursor: pointer;
      }
    }
    .upload-text {
      margin-top: 20px;
    }
    .upload-export {
      .el-icon-close {
        display: none;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.informationSetting {
  .informationSetting-content {
    margin-top: 20px;
    .filter-condition {
      font-size: 16px;
      .part {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .text {
          margin-right: 10px;
        }
      }
    }
    .filter-table {
      .btn {
        margin: 20px 10px 20px 0;
      }
    }
  }
}
</style>
