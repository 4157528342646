<template>
  <div class="style-configuration w center_all center_2 postion">
    <ul class="crumbs clearfix">
      <li>微信传播</li>
      <li>-></li>
      <li>本刊配置</li>
      <li>-></li>
      <li>本刊模板</li>
    </ul>
    <el-button class="new-btn fl" type="primary" icon="el-icon-plus" @click="newStyleConfig">新建</el-button>
    <SimpleTable
      ref="styleTable"
      class="style-configuration-table"
      showOperate
      showBorder
      btn1Text="编辑"
      btn2Text="删除"
      :tableData="styleTableData"
      :columnData="columnData"
      @onRadioClick="handleRadioClick"
      @onClickBtn1="handleEdit"
      @onClickBtn2="handleDelete"
    ></SimpleTable>
    <!-- 设为默认样式弹框 -->
    <el-dialog width="300px" class="setDefaultRadio-dialog" title="默认模板设置" :visible.sync="setDefaultRadioVisible" :show-close="true">
      <p>确定将{{ clickItem ? clickItem.name : '该模板' }}指定为本刊的默认模板吗?</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setDefaultRadioVisible = false">取 消</el-button>
        <el-button type="primary" @click="setDefaultRadioBtn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新建编辑弹框 -->
    <el-dialog width="1204px" :show-close="true" class="style-configuration-dialog" :visible.sync="editDialogVisible">
      <styleSettings ref="styleSettings" @savePublicationConfig="savePublicationConfig"></styleSettings>
    </el-dialog>
    <!-- 删除弹框 -->
    <el-dialog width="300px" class="delete-dialog" :visible.sync="deleteDialogVisible" :show-close="true">
      <p>确定删除该样式吗</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SimpleTable from '@/components/SimpleTable'
import styleSettings from './components/styleSettings'
import { GetListConfig, DeleteStyleConfig, SetDefaultStyleConfig } from '@/api/weChatPush'
import { mapGetters } from 'vuex'

export default {
  name: 'styleConfiguration',
  components: {
    SimpleTable,
    styleSettings
  },
  data() {
    return {
      styleTableData: [],
      deleteDialogVisible: false,
      editDialogVisible: false,
      setDefaultRadioVisible: false,
      deleteItem: '',
      columnData: [
        { title: '本刊模板名称', prop: 'name', notSortable: true },
        {
          title: '设为默认模板',
          prop: '',
          notSortable: true,
          radio: true,
          width: '110px'
        }
      ],
      clickItem: null
    }
  },
  computed: {
    ...mapGetters({
      magId: 'magId'
    })
  },
  watch: {
    magId() {
      this.getStyleConfigList()
    }
  },
  mounted() {
    this.getStyleConfigList()
  },
  methods: {
    //编辑
    handleEdit(val) {
      this.editDialogVisible = true
      this.$nextTick(() => {
        this.$refs.styleSettings.resetForm()
        this.$refs.styleSettings.getConfig('edit', val)
      })
    },
    //删除
    handleDelete(val) {
      this.deleteItem = val
      this.deleteDialogVisible = true
    },
    // 删除确定
    deleteBtn() {
      let params = { ids: this.deleteItem.id }
      DeleteStyleConfig(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            message: '删除成功！',
            duration: 1500,
            type: 'success'
          })
          this.getStyleConfigList()
          this.deleteDialogVisible = false
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 点击设为默认模板radio
    handleRadioClick(item, index) {
      this.setDefaultRadioVisible = true
      this.setDefaultRadio()
      this.clickItem = item
    },
    // 设为默认模板radio确定
    setDefaultRadioBtn() {
      let params = {
        magId: this.magId,
        id: this.clickItem.id
      }
      SetDefaultStyleConfig(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.getStyleConfigList()
          this.setDefaultRadioVisible = false
          this.$message({
            type: 'success',
            message: '设置成功!'
          })
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 设置默认模板参数
    setDefaultRadio() {
      this.styleTableData.map((item, index) => {
        if (item.defConfig === 1) {
          this.$set(item, 'defaultRadio', true)
        } else {
          this.$set(item, 'defaultRadio', false)
        }
      })
    },
    //新建
    newStyleConfig() {
      this.editDialogVisible = true
      this.$nextTick(() => {
        this.$refs.styleSettings.resetForm()
        this.$refs.styleSettings.getConfig('new')
      })
    },
    // 获取微网刊样式模板
    getStyleConfigList() {
      let params = {
        magId: this.magId,
        page: 0,
        size: 10
      }
      GetListConfig(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.styleTableData = data.content
          this.setDefaultRadio()
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    //保存模板样式
    savePublicationConfig() {
      this.editDialogVisible = false
      this.getStyleConfigList()
    }
  }
}
</script>
<style lang="scss">
.style-configuration {
  .el-table .cell {
    padding-left: 0;
    padding-right: 0;
  }
  .el-dialog__wrapper {
    background: rgba(0, 0, 0, 0.3) !important;
    padding: 0;
  }
  .delete-dialog {
    .el-dialog {
      margin-top: 15% !important;
    }
  }
  .style-configuration-dialog {
    z-index: 1 !important;
  }
}
.setDefaultRadio {
  .el-message-box__header {
    padding-top: 30px !important;
  }
}
</style>
<style lang="scss" scoped>
.style-configuration {
  min-height: 60%;
  .style-configuration-table {
    width: 50%;
    margin: 50px auto 0;
  }
}
</style>
