import request from './services/axios.js'
// 微网刊
//根据微网刊Id更新发布状态是否发布
export function UpdateJournalPublish(params) {
  return request({
    params,
    url: '/vas/updateJournalPublish',
    method: 'get'
  })
}
//获取每期任务基本信息
export function GetNetworkJournalInfo(params) {
  return request({
    params,
    url: '/vas/getNetworkJournalInfo',
    method: 'get'
  })
}
//分页获取微网刊样式模板
export function GetListConfig(params) {
  return request({
    params,
    url: '/vas/networkjournal/listConfig',
    method: 'get'
  })
}
//新建微网刊样式模板
export function SaveStyleConfig(params) {
  return request({
    data: params,
    url: '/vas/networkjournal/save',
    method: 'post'
  })
}
//修改微网刊样式模板
export function EditStyleConfig(params) {
  return request({
    data: params,
    url: '/vas/networkjournal/edit',
    method: 'post'
  })
}
//删除微网刊样式模板
export function DeleteStyleConfig(params) {
  return request({
    data: params,
    url: '/vas/networkjournal/deleteBatchConfig',
    method: 'post'
  })
}
//新建编辑微网刊任务
export function SaveNetworkJournal(params) {
  return request({
    data: params,
    url: '/vas/saveNetworkJournal',
    method: 'post'
  })
}
//设置默认模板样式
export function SetDefaultStyleConfig(params) {
  return request({
    params,
    url: '/vas/networkjournal/installDefConfig',
    method: 'get'
  })
}
//微网刊每期文章列表排序
export function IssueUpOrBottom(params) {
  return request({
    params,
    url: '/vas/networkArticle/sequence',
    method: 'get'
  })
}
//微网刊每期文章列表删除
export function IssueDelete(params) {
  return request({
    params,
    url: '/vas/networkArticle/delete',
    method: 'delete'
  })
}
//微网刊每期文章列表封面图上传
export function UploadIssueCover(params) {
  return request({
    params,
    url: '/vas/networkArticle/saveCover',
    method: 'get'
  })
}
//新建微网刊每期文章
export function NewIssueArticle(params) {
  return request({
    data: params,
    url: '/vas/networkArticle/save',
    method: 'post'
  })
}
//修改模板封面
export function UpdateTemplateCover(params) {
  return request({
    params,
    url: '/vas/networktemplate/saveCover',
    method: 'get'
  })
}
// 获取当前基础配置配置
export function GetConfig(params) {
  return request({
    params,
    url: '/vas/networkjournal/getConfig',
    method: 'get'
  })
}
// 修改当前基础配置配置
export function UpdateConfig(params) {
  return request({
    data: params,
    url: '/vas/networkjournal/updateNetworkConfig',
    method: 'post'
  })
}
// 修改任务列表年期信息
export function UpdateJournalPeriod(params) {
  return request({
    data: params,
    url: '/vas/updateJournalPeriod',
    method: 'post'
  })
}
// 获取最新目次期信息
export function GetNewestJournal(params) {
  return request({
    params,
    url: '/vas/getNewestJournal',
    method: 'get'
  })
}
// 获取当前任务的index位置
export function GetIndex(params) {
  return request({
    params,
    url: '/vas/networkjournal/getIndex',
    method: 'get'
  })
}
