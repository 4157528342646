<!-- yxType 医学版 -->
<template>
  <div class="yxType">
    <!--
      logo:微网刊logo设置 subject:顶部介绍封面设置 weChatShare: 微信分享设置
      mag:期刊介绍封面设置 codes:二维码设置
    -->
    <el-form ref="ruleForm" :model="ruleForm" label-width="110px">
      <logo v-if="showLogo" :ruleForm="ruleForm" :logoImg="logoImg"></logo>
      <subject v-if="showSubject" :ruleForm="ruleForm" :subjectImg="subjectImg" :maxlength="12" showSubjectEnTitle></subject>
      <mag v-if="showMag" :ruleForm="ruleForm" :magImg="magImg" :maxlength="12"></mag>
      <codes v-if="showCodes" :ruleForm="ruleForm" :codeImg="codeImg"></codes>
      <weChatShare v-if="showWeChatShare" :ruleForm="ruleForm"></weChatShare>
    </el-form>
  </div>
</template>

<script>
import logo from './module/logo'
import mag from './module/mag'
import codes from './module/codes'
import subject from './module/subject'
import weChatShare from './module/weChatShare.vue'
export default {
  name: 'yxType',
  data() {
    return {
      codeImg: require('@/assets/img/template_img/yx/module7.png'),
      logoImg: require('@/assets/img/template_img/yx/module0.png'),
      magImg: require('@/assets/img/template_img/yx/module6.png'),
      subjectImg: require('@/assets/img/template_img/yx/module9.png')
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showLogo: {
      type: Boolean,
      default: false
    },
    showSubject: {
      type: Boolean,
      default: false
    },
    showMag: {
      type: Boolean,
      default: false
    },
    showCodes: {
      type: Boolean,
      default: false
    },
    showWeChatShare: {
      type: Boolean,
      default: false
    }
  },
  components: {
    logo,
    mag,
    codes,
    subject,
    weChatShare
  },
  watch: {
    magId() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
      })
    }
  },
  computed: {},
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
