<!-- codes -->
<template>
  <div class="codes">
    <el-form ref="ruleForm" :model="ruleForm" label-width="150px">
      <!-- 二维码设置 -->
      <div class="part">
        <div class="left-part">
          <img :src="codeImg" alt="" />
        </div>
        <div class="right-part editor_box">
          <div class="flex-content">
            <div class="left">
              <el-form-item label="第一个(左侧)二维码：" size="zdy4" prop="wechatCoverId">
                <div class="wechat-cover upload-img">
                  <img class="fl" :src="wechatCoverSrc" alt />
                  <el-upload class="upload-demo" :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" :auto-upload="true" :on-success="handleCodeSuccess">
                    <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
                  </el-upload>
                </div>
              </el-form-item>
              <div slot="tip" class="el-upload__tip">
                <el-button @click="resetFirstImg" style="margin: 0px 20px 0 0">重置</el-button>
                格式支持：jpg、png、jpeg <br />
                <span style="margin-left: 80px">尺寸推荐：150*150</span>
              </div>
              <el-form-item label="第一个(左侧)名称：" prop="wechatCoverName" size="zdy6">
                <el-input v-model="ruleForm.wechatCoverName" type="text" />
              </el-form-item>
            </div>
            <div class="right">
              <el-form-item label="第二个(右侧)二维码：" size="zdy4" prop="qrCodeRightId">
                <div class="wechat-cover upload-img">
                  <img class="fl" :src="qrCodeRightCoverSrc" alt />
                  <el-upload class="upload-demo" :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" :auto-upload="true" :on-success="handleCodeSuccessRight">
                    <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
                  </el-upload>
                </div>
              </el-form-item>
              <div slot="tip" class="el-upload__tip">
                <el-button @click="resetSecondImg" style="margin: 0px 20px 0 0">重置</el-button>
                格式支持：jpg、png、jpeg <br />
                <span style="margin-left: 80px">尺寸推荐：150*150</span>
              </div>
              <el-form-item label="第二个(右侧)名称：" prop="qrCodeRightName" size="zdy6">
                <el-input v-model="ruleForm.qrCodeRightName" type="text" />
              </el-form-item>
            </div>
          </div>
          <div class="flex-content">
            <div class="left">
              <el-form-item label="第三个二维码：" size="zdy4" prop="wechatCoverId3">
                <div class="wechat-cover upload-img">
                  <img class="fl" :src="wechatCoverThirdCoverSrc" alt />
                  <el-upload class="upload-demo" :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" :auto-upload="true" :on-success="handleThirdCodeSuccess">
                    <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
                  </el-upload>
                </div>
              </el-form-item>
              <div slot="tip" class="el-upload__tip">
                <el-button @click="resetThirdImg" style="margin: 0px 20px 0 0">重置</el-button>
                格式支持：jpg、png、jpeg <br />
                <span style="margin-left: 80px">尺寸推荐：150*150</span>
              </div>
              <el-form-item label="第三个二维码名称：" prop="wechatCoverName" size="zdy6">
                <el-input v-model="ruleForm.wechatCoverName3" type="text" />
              </el-form-item>
            </div>
            <div class="right">
              <el-form-item label="第四个二维码：" size="zdy4" prop="wechatCoverId4">
                <div class="wechat-cover upload-img">
                  <img class="fl" :src="wechatCoverFourthCoverSrc" alt />
                  <el-upload class="upload-demo" :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" :auto-upload="true" :on-success="handleFourthCodeSuccess">
                    <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
                  </el-upload>
                </div>
              </el-form-item>
              <div slot="tip" class="el-upload__tip">
                <el-button @click="resetFourthImg" style="margin: 0px 20px 0 0">重置</el-button>
                格式支持：jpg、png、jpeg <br />
                <span style="margin-left: 80px">尺寸推荐：150*150</span>
              </div>
              <el-form-item label="第四个二维码名称：" prop="qrCodeRightName" size="zdy6">
                <el-input v-model="ruleForm.wechatCoverName4" type="text" />
              </el-form-item>
            </div>
          </div>
          <el-form-item label="背景图片：" size="zdy4" prop="qrCodeBackgroundId">
            <div class="wechatBg-cover upload-img">
              <img :src="qrCodeBackgroundCoverSrc" alt />
              <el-upload class="upload-demo" :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" :auto-upload="true" :on-success="handleQrBgCodeSuccess">
                <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
              </el-upload>
            </div>
          </el-form-item>
          <div slot="tip" class="el-upload__tip">
            <el-button @click="resetBgImg" style="margin: 0px 20px 0 0">重置</el-button>格式支持：jpg、png、jpeg <span class="betterSize">尺寸推荐：346*440</span>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { GetPictureStream, UploadPictureStream } from '@/api/configManage'
export default {
  name: 'codes',
  data() {
    return {
      morImg: require('@/assets/img/img_default.png'),
      codeMorImg: require('@/assets/img/codeMorImg.jpg'),
      GetPictureStream: GetPictureStream,
      UploadPictureStream: UploadPictureStream,
      wechatCover: '',
      qrCodeRightCover: '',
      qrCodeBackgroundCover: '',
      wechatCoverThirdCover: '',
      wechatCoverFourthCover: ''
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    codeImg: {
      default: require('@/assets/img/template_img/zk/module7.png')
    }
  },
  components: {},
  watch: {
    magId() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
      })
    }
  },
  computed: {
    //左侧微信二维码
    wechatCoverSrc() {
      return this.wechatCover ? this.wechatCover : this.ruleForm.wechatCoverId ? `${this.GetPictureStream}?pictureId=${this.ruleForm.wechatCoverId}` : this.morImg
    },
    //右侧微信二维码
    qrCodeRightCoverSrc() {
      return this.qrCodeRightCover ? this.qrCodeRightCover : this.ruleForm.qrCodeRightId ? `${this.GetPictureStream}?pictureId=${this.ruleForm.qrCodeRightId}` : this.codeMorImg
    },
    //二维码背景图片
    qrCodeBackgroundCoverSrc() {
      return this.qrCodeBackgroundCover ? this.qrCodeBackgroundCover : this.ruleForm.qrCodeBackgroundId ? `${this.GetPictureStream}?pictureId=${this.ruleForm.qrCodeBackgroundId}` : this.morImg
    },
    //第三个二维码图片
    wechatCoverThirdCoverSrc() {
      return this.wechatCoverThirdCover ? this.wechatCoverThirdCover : this.ruleForm.wechatCoverId3 ? `${this.GetPictureStream}?pictureId=${this.ruleForm.wechatCoverId3}` : this.morImg
    },
    //第四个二维码图片
    wechatCoverFourthCoverSrc() {
      return this.wechatCoverFourthCover ? this.wechatCoverFourthCover : this.ruleForm.wechatCoverId4 ? `${this.GetPictureStream}?pictureId=${this.ruleForm.wechatCoverId4}` : this.morImg
    }
  },
  created() {},
  methods: {
    // 成功上传左侧code图
    handleCodeSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.wechatCover = URL.createObjectURL(file.raw)
      this.ruleForm.wechatCoverId = ID
    },
    // 成功上传右侧侧code图
    handleCodeSuccessRight(res, file) {
      const { data } = res
      const { ID } = data
      this.qrCodeRightCover = URL.createObjectURL(file.raw)
      this.ruleForm.qrCodeRightId = ID
    },
    //成功上传微网刊二维码背景图
    handleQrBgCodeSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.qrCodeBackgroundCover = URL.createObjectURL(file.raw)
      this.ruleForm.qrCodeBackgroundId = ID
    },
    // 成功上传第三个二维码
    handleThirdCodeSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.wechatCoverThirdCover = URL.createObjectURL(file.raw)
      this.ruleForm.wechatCoverId3 = ID
    },
    // 成功上传第四个二维码
    handleFourthCodeSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.wechatCoverFourthCover = URL.createObjectURL(file.raw)
      this.ruleForm.wechatCoverId4 = ID
    },
    // 重置第一个二维码
    resetFirstImg() {
      this.wechatCover = ''
      this.ruleForm.wechatCoverId = 0
    },
    // 重置第二个二维码
    resetSecondImg() {
      this.qrCodeRightCover = this.codeMorImg
      this.ruleForm.qrCodeRightId = 0
    },
    // 重置第三个二维码
    resetThirdImg() {
      this.wechatCoverThirdCover = ''
      this.ruleForm.wechatCoverId3 = 0
    },
    // 重置第四个二维码
    resetFourthImg() {
      this.wechatCoverFourthCover = ''
      this.ruleForm.wechatCoverId4 = 0
    },
    // 二维码背景重置
    resetBgImg() {
      this.qrCodeBackgroundCover = this.morImg
      this.ruleForm.qrCodeBackgroundId = 0
    }
  }
}
</script>
<style lang="scss">
.codes {
  .right-part .flex-content .left {
    width: 50% !important;
  }
  .el-upload__tip {
    margin: 0 0 20px 60px !important;
  }
}
</style>
<style lang="scss" scoped>
.codes {
  .left-part {
    height: 605px;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .wechat-cover {
    width: 150px;
    height: 150px;
    .upload-demo {
      width: 150px !important;
      height: 150px !important;
    }
  }
  .wechatBg-cover {
    width: 173px;
    height: 220px;
    .upload-demo {
      width: 173px !important;
      height: 220px !important;
    }
  }
}
</style>
