<template>
  <div class="w center_all">
    <div class="center_2">
      <ul class="crumbs clearfix">
        <li>领域分析</li>
        <li>-></li>
        <li>机构论文</li>
      </ul>
      <!-- 摘要 -->
      <div class="abstract">
        <div class="title_z" v-text="articleInfoData.title || '--'"></div>
        <!--        <div class="title_e" v-text="articleInfoData.magEn || '&#45;&#45;'"></div>-->
        <div class="abstract_t" v-text="articleInfoData.abstractContent ? `摘要：${articleInfoData.abstractContent}` : '--'"></div>
        <ul class="more">
          <li><span>关键词：</span>{{ articleInfoData.keywords ? articleInfoData.keywords.slice(1, articleInfoData.keywords.length - 1) : '--' }}</li>
          <li><span>作者：</span>{{ articleInfoData.authors }}</li>
          <!--          <li><span>Author：</span>{{}}</li>-->
          <li><span>作者单位：</span>{{ articleInfoData.institutions || '--' }}</li>
          <li><span>刊名：</span>{{ articleInfoData.magCn || '--' }}</li>
          <li><span>Journal：</span>{{ articleInfoData.magEn || '--' }}</li>
          <li><span>年，卷：</span>{{ articleInfoData.magNumber && articleInfoData.year ? `${articleInfoData.year}，${articleInfoData.magNumber}` : '--' }}</li>
          <li><span>所属期刊栏目：</span>{{ articleInfoData.columnBelong || '--' }}</li>
          <li><span>分类号：</span>{{ articleInfoData.classNumber || '--' }}</li>
        </ul>
      </div>
      <el-tabs v-model="activeName" type="card" class="tabs2">
        <el-tab-pane label="论文引证分析" name="citation"></el-tab-pane>
        <el-tab-pane label="论文引用分析" name="quote"></el-tab-pane>
      </el-tabs>
      <!-- 饼图 -->
      <!--      <h2 class="center_title mb-20">期刊引证情况</h2>-->
      <div class="conte_1">
        <div class="conte_tit_1 btn"></div>
        <div id="myChart" :style="{ width: '520px', height: '350px' }" />
        <!-- 所属机构统计 -->
        <div class="conte_table">
          <SimpleTable v-if="activeName === 'citation'" no-pagination chart-table max-height="450" :tableData="tableData" :columnData="columnData" />
          <SimpleTable v-else no-pagination chart-table max-height="450" :tableData="tableData" :columnData="referenceColumnData" />
        </div>
      </div>
      <!--引证论文列表-->
      <div class="mb-20 conter_3">
        <h2 class="center_title mb-20" v-text="activeName === 'citation' ? '引证论文列表' : '引用论文列表'"></h2>
        <SimpleTable
          ref="citationTable"
          v-if="activeName === 'citation'"
          :tableData="articleTableData"
          :columnData="citationColumn"
          :initObj="citationArticlePageObj"
          @onChangePageData="
            (pageObj) => {
              getCitationList(pageObj)
            }
          "
          @onChangeSort="
            (prop, order) => {
              handleChangeSort(prop, order, 'citation')
            }
          "
        />
        <SimpleTable
          ref="quoteTable"
          v-if="activeName === 'quote'"
          :tableData="quoteArticleTableData"
          :columnData="citationColumn"
          :initObj="quoteArticlePageObj"
          @onChangePageData="
            (pageObj) => {
              getQuoteList(pageObj)
            }
          "
          @onChangeSort="
            (prop, order) => {
              handleChangeSort(prop, order, 'quote')
            }
          "
        />
      </div>
      <!--      <div class="sort clearfix">-->
      <!--        <span class="fl">排序： </span>-->
      <!--        <el-radio-group v-model="radio1" class="fl" size="small" @change="sorTable">-->
      <!--          <el-radio-button label="发表时间" />-->
      <!--          <el-radio-button label="被引量" />-->
      <!--          <el-radio-button label="期刊" />-->
      <!--        </el-radio-group>-->
      <!-- <div class="fr clearfix"> -->
      <!-- <div class="btn_table"></div>
                  <div class="btn_list"></div> -->
      <!--        <el-radio-group v-model="radio2" class="fr btn_listTable" size="small" @change="listTable">-->
      <!--          <el-radio-button label="1" class="btn_table" />-->
      <!--          <el-radio-button label="2" class="btn_list" />-->
      <!--        </el-radio-group>-->
      <!-- </div> -->
      <!--      </div>-->
    </div>
  </div>
</template>
<script>
import SimpleTable from '@/components/SimpleTable'
import { GetArticleInfo, CitationArticlesAnalysis, ReferenceArticlesAnalysis, GetSingleCitationArticlesList, GetSingleReferenceArticlesList } from '@/api/article'
export default {
  name: 'Quote',
  components: {
    SimpleTable
  },
  data() {
    return {
      chooseArticleId: '', // 文章的Id
      articleInfoData: {}, // 文章元数据的对象
      activeName: 'citation',
      citationArticlePageObj: {
        page: 1,
        size: 10
      },
      quoteArticlePageObj: {
        page: 1,
        size: 10
      },
      tableData: [],
      columnData: [
        {
          title: '引证期刊',
          prop: 'magName',
          notSortable: true
        },
        {
          title: '引证次数',
          prop: 'count'
        },
        {
          title: '占比',
          prop: 'percent'
        }
      ],
      referenceColumnData: [
        {
          title: '引用期刊',
          prop: 'magName',
          notSortable: true
        },
        {
          title: '引用次数',
          prop: 'count'
        },
        {
          title: '占比',
          prop: 'percent'
        }
      ],
      quoteColumnData: [
        {
          title: '引用期刊',
          prop: 'magName',
          special: true,
          notSortable: true
        },
        {
          title: '引用次数',
          prop: 'count'
        },
        {
          title: '占比',
          prop: 'percent'
        }
      ],
      articleTableData: [], // 引证论文列表
      quoteArticleTableData: [], // 引用论文列表
      citationColumn: [
        {
          title: '论文名称',
          prop: 'title',
          notSortable: true
        },
        {
          title: '期刊',
          prop: 'magCn',
          notSortable: true
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '作者单位',
          prop: 'institutions',
          notSortable: true
        },
        {
          title: '年份',
          prop: 'year'
        },
        {
          title: '刊期',
          prop: 'magNumber'
        }
      ],
      // 排序时候需要对应转换一下（因为返回的和传的值不一样）[引证]
      citationSortDictionary: {
        year: 'rootYear',
        magNumber: 'rootMagNumber'
      },
      quoteSortDictionary: {
        year: 'referencedYear',
        magNumber: 'referencedNumber'
      }
      // radio1: '发表时间',
      // radio2: '1', // 默认选择
    }
  },
  mounted() {
    this.chooseArticleId = this.$route.query.searchId
    this.getArticleInfo()
    this.getCitationArticles()
    this.getCitationList()
  },
  watch: {
    activeName(nv) {
      if (nv === 'citation') {
        this.getCitationArticles()
        this.getCitationList()
        this.clearAllTableSort()
        this.$nextTick(() => {
          this.$refs.citationTable.clearSort()
        })
      } else {
        this.getQuoteArticles()
        this.getQuoteList()
        this.clearAllTableSort()
        this.$nextTick(() => {
          this.$refs.quoteTable.clearSort()
        })
      }
    }
  },
  methods: {
    clearAllTableSort() {
      this.citationArticlePageObj.sort = ''
      this.quoteArticlePageObj.sort = ''
    },
    handleChangeSort(prop, order, tableName) {
      if (tableName === 'quote') {
        // 引用列表排序改变
        let sortValue = ''
        if (order && order !== '') {
          if (order === 'ascending') {
            sortValue = this.quoteSortDictionary[prop] + ',asc'
          } else {
            sortValue = this.quoteSortDictionary[prop] + ',desc'
          }
        }
        this.quoteArticlePageObj.sort = sortValue
        this.getQuoteList()
      } else if (tableName === 'citation') {
        // 引证列表排序改变
        let sortValue = ''
        if (order && order !== '') {
          if (order === 'ascending') {
            sortValue = this.citationSortDictionary[prop] + ',asc'
          } else {
            sortValue = this.citationSortDictionary[prop] + ',desc'
          }
        }
        this.citationArticlePageObj.sort = sortValue
        this.getCitationList()
      }
    },
    // 获取论文引用列表
    getQuoteList(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        id: this.chooseArticleId,
        page: page - 1,
        size,
        sort: this.quoteArticlePageObj.sort || ''
      }
      GetSingleReferenceArticlesList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.quoteArticlePageObj = {
            ...this.quoteArticlePageObj,
            page,
            size,
            total: totalElements
          }
          this.quoteArticleTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取论文引用分析数据
    getQuoteArticles() {
      const params = {
        id: this.chooseArticleId
      }
      ReferenceArticlesAnalysis(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data = [] } = res.data
          this.tableData = data
          this.getPieChart(this.tableData)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取论文引证列表
    getCitationList(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        id: this.chooseArticleId,
        page: page - 1,
        size,
        sort: this.citationArticlePageObj.sort || ''
      }
      GetSingleCitationArticlesList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.citationArticlePageObj = {
            ...this.citationArticlePageObj,
            page,
            size,
            total: totalElements
          }
          this.articleTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取论文引证分析数据
    getCitationArticles() {
      const params = {
        id: this.chooseArticleId
      }
      CitationArticlesAnalysis(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data = [] } = res.data
          this.tableData = data
          this.getPieChart(this.tableData)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取文章元数据
    getArticleInfo() {
      const params = {
        id: this.chooseArticleId
      }
      GetArticleInfo(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data = {} } = res.data
          this.articleInfoData = data
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    handleSizeChange(val) {
      // table 分页 每页条数
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      // table 分页 当前页
      console.log(`当前页: ${val}`)
    },
    handleSizeChange1(val) {
      // list 分页 每页条数
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange1(val) {
      // list 分页 当前页
      console.log(`当前页: ${val}`)
    },
    getPieChart(data = []) {
      // let pieArray = JSON.parse(JSON.stringify(data))
      const pieArray = data.map((item) => {
        return {
          name: item.magName,
          value: item.count
        }
      })
      let myChart = this.$echarts.init(document.getElementById('myChart'))

      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: '引证期刊',
            type: 'pie',
            color: ['#37a2da', '#32c4e9', '#66e0e3', '#9fe7b9', '#fedb5b', '#ff9f7f', '#fc7293', '#e061ae', '#e690d1', '#e7bcf2'],
            // radius: ['30%', '50%'],
            // center: ['22%', '55%'],
            radius: '55%',
            center: ['50%', '50%'],
            label: {
              normal: {
                formatter: '{d}%'
              }
            },
            data: pieArray,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
    },
    // 排序 选择
    sorTable(val) {
      console.log(val)
    },
    // 表格 列表 切换
    listTable(val) {
      console.log(val)
      if (val === 1) {
        this.tabList = true
      } else {
        this.tabList = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.conte_1 {
  background-color: #f6f7fc;
  position: relative;
  margin-bottom: 20px;
}
.conte_tit_1 {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 200;
}
.tj_year {
  color: #999;
  font-size: 14px;
  position: relative;
  top: -3px;
  margin-right: 10px;
}
.conte_table {
  width: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
}
.abstract {
  text-align: left;
}
.title_z {
  font-size: 20px;
  color: #333333;
  line-height: 30px;
  margin-top: 10px;
}
.title_e {
  font: 14px;
  color: #999;
  line-height: 20px;
  margin-bottom: 10px;
}
.abstract_t {
  font-size: 14px;
  line-height: 24px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.more {
  margin-top: 10px;
  margin-bottom: 10px;
}
.more li {
  line-height: 24px;
  font-size: 14px;
  color: #999;
}
.more li span {
  color: #666;
}
.sort {
  text-align: left;
}
.sort > span {
  height: 32px;
  line-height: 12px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 9px 10px 9px 0;
  color: #999;
}
.conter_list {
  text-align: left;
}
.conter_list > li {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
}
.conter_list_title {
  font-size: 16px;
  color: #333;
  line-height: 24px;
}
.conter_list_more {
  color: #999;
  font-size: 14px;
  line-height: 24px;
}
.conter_list_more li {
  float: left;
  margin-right: 14px;
}
.conter_list_abstract {
  margin-top: 10px;
  color: #666;
  line-height: 24px;
  font-size: 14px;
}
.conter_list_keywords {
  margin-top: 10px;
  font-size: 14px;
  color: #999;
  line-height: 18px;
}
</style>
