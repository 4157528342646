<!-- zkType 自科版 -->
<template>
  <div class="zkType">
    <!-- color:选择配色 logo:微网刊logo设置 subject:顶部介绍封面设置
    mag:期刊介绍封面设置 codes:二维码设置 defalutCover:论文默认封面设置
    carousel: 轮播图设置 weChatShare: 微信分享设置 -->
    <el-form ref="ruleForm" :model="ruleForm" label-width="110px">
      <color v-if="showColor" :ruleForm="ruleForm"></color>
      <logo v-if="showLogo" :ruleForm="ruleForm" :logoImg="logoImg"></logo>
      <subject v-if="showSubject" :ruleForm="ruleForm" :subjectImg="subjectImg"></subject>
      <mag v-if="showMag" :ruleForm="ruleForm" :magImg="magImg"></mag>
      <codes v-if="showCodes" :ruleForm="ruleForm" :codeImg="codeImg"></codes>
      <carousel v-if="showCarousel" :ruleForm="ruleForm" :carouselImg="carouselImg" :carouselTableData="carouselTableData"></carousel>
      <weChatShare v-if="showWeChatShare" :ruleForm="ruleForm"></weChatShare>
    </el-form>
  </div>
</template>

<script>
import color from './module/color'
import logo from './module/logo'
import mag from './module/mag'
import codes from './module/codes'
import subject from './module/subject'
import carousel from './module/carousel.vue'
import weChatShare from './module/weChatShare.vue'
export default {
  name: 'zkType',
  data() {
    return {
      codeImg: require('@/assets/img/template_img/zk/module7.png'),
      defaultCoverImg: require('@/assets/img/template_img/zk/module3.png'),
      logoImg: require('@/assets/img/template_img/zk/module0.png'),
      magImg: require('@/assets/img/template_img/zk/module6.png'),
      subjectImg: require('@/assets/img/template_img/zk/module9.png'),
      carouselImg: require('@/assets/img/template_img/zk/module1.png')
      // chooseCarouseArticleList: []
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    carouselTableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    showColor: {
      type: Boolean,
      default: false
    },
    showLogo: {
      type: Boolean,
      default: false
    },
    showSubject: {
      type: Boolean,
      default: false
    },
    showMag: {
      type: Boolean,
      default: false
    },
    showCodes: {
      type: Boolean,
      default: false
    },
    showDefalutCover: {
      type: Boolean,
      default: false
    },
    showCarousel: {
      type: Boolean,
      default: false
    },
    showWeChatShare: {
      type: Boolean,
      default: false
    },
    chooseCarouseArticleList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    color,
    logo,
    mag,
    codes,
    subject,
    carousel,
    weChatShare
  },
  watch: {
    magId() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
      })
    }
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
