import request from './services/axios.js'

// 资讯聚合相关接口

// 获取资讯或会议列表
export function GetInformationList(params) {
  return request({
    params,
    url: '/vas/information/list',
    method: 'get'
  })
}
// 一键转载
export function Reprint(params) {
  return request({
    params,
    url: '/vas/information/reprint',
    method: 'get'
  })
}
// 资讯聚合-配置管理-导入资讯信源-采集任务列表
export function GetListTask(params) {
  return request({
    params,
    url: '/vas/information/listTask',
    method: 'get'
  })
}
// 资讯聚合-配置管理-导入资讯信源-批量删除任务
export function BatchDelete(params) {
  return request({
    params,
    url: '/vas/information/batchDelete',
    method: 'get'
  })
}
// 资讯聚合-配置管理-导入资讯信源-下载模板
export const ImportTemplateDownload = PROJECT_CONFIG.BASE_URL + '/vas/information/importTemplateDownload'
// 资讯聚合-配置管理-导入资讯信源-导入资讯采集任务
export const ImportInformationTask = PROJECT_CONFIG.BASE_URL + '/vas/information/importInformationTask'
// 资讯聚合-配置管理-导入资讯信源-导出资讯采集任务
export const ExportTask = PROJECT_CONFIG.BASE_URL + '/vas/information/exportTask'
