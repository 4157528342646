<template>
  <div class="w center_all">
    <div class="center_2 postion informationAggregation">
      <ul class="z_tabs">
        <li :class="currentTabIndex == 2 ? 'active' : ''" @click="changeTab(2)">学术资讯</li>
        <li :class="currentTabIndex == 1 ? 'active poR' : 'poR'" @click="changeTab(1)">会议资讯</li>
      </ul>
      <div class="postion z_tabDiv1">
        <ul class="crumbs clearfix">
          <li>资讯聚合</li>
          <li>-></li>
          <li>{{ currentTabName }}</li>
        </ul>
        <div class="tableList-search">
          <div class="text">资讯检索：</div>
          <el-input placeholder="请输入内容" v-model="searchVal" class="input-with-select">
            <el-select v-model="selectVal" slot="prepend" placeholder="请选择">
              <el-option v-for="(item, index) in selectList" :key="index" :label="item.label" :value="item.value" />
            </el-select>
            <el-button slot="append" icon="el-icon-search" @click="getInformationList"></el-button>
          </el-input>
        </div>
        <div class="sort sort1 clearfix">
          <el-button class="fl" size="medium" round @click="clearMultipleSelection" v-if="isTable">清除</el-button>
          <div v-if="!isTable && showAllCheck" style="float:left;margin-top:5px"><input type="checkbox" @click="checkAllItem" />全选</div>
          <el-button class="fl" size="medium" round @click="clearMultipleSelection" v-if="!isTable && !showAllCheck">清除</el-button>
          <span class="fl">
            您已选择
            <span class="c_red" v-if="isTable">{{ multipleSelection.length }}</span>
            <span class="c_red" v-else>{{ checkedListId.length }}</span>
            条
          </span>
          <span class="fl btnzz" @click="reprint">一键转载</span>

          <div class="clearfix fr sort ">
            <span class="fl ml-20">排序： </span>
            <el-checkbox-group v-model="sortRadio" class="fl">
              <el-checkbox-button size="small" label="issueTime">发布时间</el-checkbox-button>
            </el-checkbox-group>
            <span class="fl">|</span>
            <!--       切换表格or摘要     -->
            <el-radio-group v-model="listTableRadio" class="fl btn_listTable" size="small" @change="listTable">
              <el-radio-button label="1" class="btn_table" />
              <el-radio-button label="2" class="btn_list" />
            </el-radio-group>
          </div>
          <div class="btn3 fr clearfix">
            <el-select v-model="chooseStatus" placeholder="转载状态" class="fl" style="width:120px;top: 3px;">
              <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <span class="tj_year fl ml-20">统计时间:</span>
            <el-select v-model="chooseTime" placeholder="请选择" class="fl" style="width:120px;top: 3px;">
              <el-option v-for="item in timeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
        </div>

        <div v-if="isTable">
          <div class="conter_3">
            <SimpleTable
              ref="table"
              hasSelect
              @onClickTitle="handleOpenUrl"
              :tableData="articleTableData"
              :columnData="articleColumn"
              :initObj="articlePageObj"
              @onChangePageData="
                (pageObj) => {
                  changePageData(pageObj)
                }
              "
              @onChangeSelection="handleSelectionChange"
            />
          </div>
        </div>
        <div v-else>
          <ul class="conter_list">
            <li v-for="(item, index) in articleTableData" :key="index" :value="item.id" ref="listItem">
              <div class="conter_list_title">
                <input type="checkbox" ref="check" :value="item.id" @click="checkAlone(item)" />
                <div
                  @click="
                    () => {
                      handleOpenUrl(item)
                    }
                  "
                >
                  {{ (articlePageObj.page - 1) * articlePageObj.size + index + 1 }}.{{ item.title }}
                </div>
                <el-button class="fr" :class="item.reprintStatus === '已转载' ? 'yiz' : 'weiz'" size="medium" round>{{ item.reprintStatus }}</el-button>
              </div>
              <ul class="conter_list_more clearfix">
                <li>
                  <span>来源：</span>
                  <span>{{ item.siteName }}</span>
                </li>
                <li>
                  <span>频道：</span>
                  <span>{{ item.channelName }}</span>
                </li>
                <li>
                  <span>发布时间：</span>
                  <span>{{ item.issueTime }}</span>
                </li>
              </ul>
              <div class="conter_list_abstract" v-html="item.informationAbstract"></div>
            </li>
          </ul>
          <Pagination
            :initObj="articlePageObj"
            @onChangePageData="
              (pageObj) => {
                changePageData(pageObj)
              }
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SimpleTable from '@/components/SimpleTable'
import Pagination from '@/components/Pagination'
import { GetInformationList, Reprint } from '@/api/InformationAggregation'
import { mapGetters } from 'vuex'

export default {
  components: {
    SimpleTable,
    Pagination
  },
  computed: {
    ...mapGetters({
      magId: 'magId'
    }),
    currentTabName() {
      return this.currentTabIndex == 2 ? '学术资讯' : '会议资讯'
    }
  },
  watch: {
    magId() {
      this.getInformationList()
    },
    sortRadio() {
      this.getInformationList()
    },
    chooseStatus() {
      this.getInformationList()
    },
    chooseTime() {
      this.getInformationList()
    }
  },
  data() {
    return {
      currentTabIndex: 2, // 2为学术资讯 1为会议资讯
      activeName: 'first',
      timeOptions: [
        {
          value: 7,
          label: '最近一周'
        },
        {
          value: 30,
          label: '最近一个月'
        },
        {
          value: 92,
          label: '最近三个月'
        },
        {
          value: 183,
          label: '最近半年'
        }
      ],
      chooseTime: 7, // 默认选择时间
      chooseStatus: '',
      statusOptions: [
        {
          value: 1,
          label: '已转载'
        },
        {
          value: 0,
          label: '未转载'
        }
      ],
      articleTableData: [],
      multipleSelection: [], // 选中列表
      sortRadio: [], // 按照发表时间排序
      listTableRadio: '2',
      isTable: false, // 表格还是列表 true 为表格 ，false为列表
      checkAll: false,
      isIndeterminate: false,
      checkedId: [],
      checkedListId: [],
      checkList: [],
      showAllCheck: true,
      articleColumn: [
        {
          title: '标题',
          prop: 'title',
          width: '300px',
          notSortable: true,
          special: true
        },
        {
          title: '转载状态',
          prop: 'reprintStatus',
          notSortable: true
        },
        {
          title: '信息来源',
          prop: 'siteName',
          notSortable: true
        },
        {
          title: '频道',
          prop: 'channelName',
          notSortable: true
        },
        {
          title: '发布时间',
          prop: 'issueTime',
          notSortable: true
        }
      ], // 文章的column
      articlePageObj: {
        page: 1,
        size: 10
      },
      informationIds: '',
      currentPageId: [],
      selectList: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 'TITLE',
          label: '标题'
        },
        {
          value: 'CHANNEL_NAME',
          label: '频道'
        },
        {
          value: 'HTML_CONTENT',
          label: '摘要'
        },
        {
          value: 'SITE_NAME',
          label: '信息来源'
        }
      ],
      searchVal: '',
      selectVal: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getInformationList()
    })
  },
  methods: {
    // tab切换
    changeTab(val) {
      this.currentTabIndex = val
      this.checkedListId = []
      this.multipleSelection = []
      this.showAllCheck = true
      this.isTable = false
      this.selectVal = ''
      this.searchVal = ''
      this.getInformationList()
      this.listTableRadio = '2'
    },
    // 表格 列表 切换
    listTable(val) {
      if (val === '1') {
        this.isTable = true
        this.handleHaveCheckedData()
      } else {
        this.isTable = false
      }
      this.getCurrentPageItemId()
    },
    // 处理列表表格切换选中已选数据
    handleHaveCheckedData() {
      let arr = []
      this.articleTableData.map((item) => {
        if (this.checkedListId.indexOf(item.id) != -1) {
          arr.push(item)
        }
      })
      this.$nextTick(() => {
        if (arr.length > 0) {
          arr.map((item) => {
            this.$refs.table.$refs.simpleTable.toggleRowSelection(item, true)
          })
        }
      })
    },
    // 列表信息
    getInformationList(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        type: this.currentTabIndex,
        day: this.chooseTime,
        reprintStatus: this.chooseStatus,
        page: page - 1,
        size,
        sort: this.sortRadio.length > 0 ? 'issueTime,DESC' : '',
        field: this.selectVal,
        value: this.searchVal
      }
      GetInformationList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (data) {
            const { content = [], totalElements = 0 } = data
            this.articlePageObj = {
              ...this.articlePageObj,
              page,
              size,
              total: totalElements
            }
            this.articleTableData = content
            this.handleTableData()
            if (!this.isTable) {
              this.getCurrentPageItemId()
            } else {
              this.handleHaveCheckedData()
            }
          } else {
            this.articleTableData = []
          }
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 处理列表信息转载状态
    handleTableData() {
      return this.articleTableData.map((item, index) => {
        item.reprintStatus = item.reprintStatus === 1 ? '已转载' : '未转载'
      })
    },
    // table选中数据改变
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 清除选中列表数据
    clearMultipleSelection() {
      this.$nextTick(() => {
        if (this.isTable) {
          this.$refs.table.clearSelection()
        } else {
          this.checkedListId = []
          if (this.$refs.check) {
            this.$refs.check.map((item, index) => {
              item.checked = false
            })
          }
          this.showAllCheck = true
        }
        this.informationIds = ''
      })
    },
    // 获取当前页数据id
    getCurrentPageItemId() {
      this.$nextTick(() => {
        if (!this.isTable) {
          this.currentPageId = []
          if (this.$refs.listItem) {
            this.$refs.listItem.map((item) => {
              this.currentPageId.push(item.value)
            })
            this.forEachChecked()
          }
        }
      })
    },
    // 列表全选数据
    checkAllItem() {
      this.checkedListId = this.currentPageId
      if (!this.$refs.check) {
        return false
      }
      this.$refs.check.map((item, index) => {
        if (this.checkedListId.indexOf(Number(item.value)) != -1) {
          this.$refs.check[index].checked = true
        } else {
          this.$refs.check[index].checked = false
        }
      })
      this.showAllCheck = false
    },
    // 列表单独选数据
    checkAlone(item) {
      if (this.checkedListId.length > 0) {
        if (this.checkedListId.indexOf(Number(item.id)) != -1) {
          this.checkedListId.splice(this.checkedListId.indexOf(Number(item.id)), 1)
        } else {
          this.checkedListId.push(item.id)
        }
      } else {
        this.checkedListId.push(item.id)
      }
    },
    // 循环选中的item，获取选中的id
    forEachChecked() {
      this.$nextTick(() => {
        this.currentPageId.map((item, index) => {
          if (this.checkedListId.indexOf(Number(item)) != -1) {
            this.$refs.check[index].checked = true
          } else {
            this.$refs.check[index].checked = false
          }
        })
      })
    },
    // 页码改变
    changePageData(pageObj) {
      this.getInformationList(pageObj)
      if (!this.isTable) {
        this.getCurrentPageItemId()
      }
    },
    //  一键转载
    reprint() {
      this.informationIds = ''
      if (this.isTable) {
        if (this.multipleSelection.length == 0) {
          this.$message.error('请选择要转载的文章！')
        } else {
          this.multipleSelection.map((item, index) => {
            if (index === this.multipleSelection.length - 1) {
              this.informationIds += item.id
            } else {
              this.informationIds += item.id + ','
            }
          })
          this.enterReprint()
        }
      } else {
        if (this.checkedListId.length == 0) {
          this.$message.error('请选择要转载的文章！')
        } else {
          this.checkedListId.map((item, index) => {
            if (index === this.checkedListId.length - 1) {
              this.informationIds += item
            } else {
              this.informationIds += item + ','
            }
          })
          this.enterReprint()
        }
      }
    },
    enterReprint() {
      this.$confirm('', '是否转载到发布中心?', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
        center: true,
        iconClass: 'information'
      })
        .then(() => {
          const params = {
            magId: this.magId,
            informationIds: this.informationIds
          }
          Reprint(params).then((res) => {
            if (res && res.data.status === 0) {
              this.$message({
                type: 'success',
                message: '转载成功!'
              })
              this.getInformationList()
              this.clearMultipleSelection()
            } else {
              const { message } = res.data
              this.$message.error(message)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 点击标题
    handleOpenUrl(chooseItem) {
      const { url = '' } = chooseItem
      if (url && url !== '') {
        window.open(url)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.postion {
  position: relative;
}
.z_tabs {
  position: absolute;
  top: 88px;
  left: -42px;
}
.z_tabs li {
  color: #fff;
  box-sizing: border-box;
  font-size: 16px;
  padding: 14px 13px 0;
  line-height: 18px;
  width: 42px;
  height: 146px;
  background-image: url('../../../public/imgs/tab02.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  position: relative;
  z-index: 1;
  font-weight: 600;
}
.poR {
  top: -26px;
}
.z_tabs li.active {
  color: #265cca;
  background-image: url('../../../public/imgs/tab01.png');
  z-index: 2;
}
.z_tabDiv1 {
  padding-top: 50px;
  min-height: 300px;
}
.ml-20 {
  margin-left: 20px;
}
.el-button--medium.is-round.yiz {
  color: #2db897;
  width: 50px;
  height: 20px;
  font-size: 12px;
  border-color: #2db897;
  padding: 0;
  text-align: center;
  line-height: 18px;
  margin-top: 2px;
  cursor: inherit;
}
.el-button--medium.is-round.weiz {
  color: #999;
  width: 50px;
  height: 20px;
  font-size: 12px;
  border-color: #ccc;
  padding: 0;
  text-align: center;
  line-height: 18px;
  margin-top: 2px;
  cursor: inherit;
}
.el-button--medium.is-round {
  padding: 5px 20px;
  position: relative;
  top: 3px;
}
.conte_1 {
  background-color: #f6f7fc;
  position: relative;
  margin-bottom: 20px;
}
.conte_tit_1 {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 200;
}
.tj_year {
  height: 32px;
  line-height: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  padding: 9px 10px 9px 0;
  color: #999;
}
.conte_table {
  width: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
}
.abstract {
  text-align: left;
}
.title_z {
  font-size: 20px;
  color: #333333;
  line-height: 30px;
  margin-top: 10px;
}
.title_e {
  font: 14px;
  color: #999;
  line-height: 20px;
  margin-bottom: 10px;
}
.abstract_t {
  font-size: 14px;
  line-height: 24px;
  color: #666;
}
.more {
  margin-top: 10px;
  margin-bottom: 10px;
}
.more li {
  line-height: 24px;
  font-size: 14px;
  color: #999;
}
.more li span {
  color: #666;
}
.sort {
  text-align: left;
}
.sort1 {
  margin-bottom: 7px;
}
.sort > span {
  height: 32px;
  line-height: 12px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 9px 10px;
  color: #999;
}
.sort > span.btnzz {
  border-radius: 3.75rem;
  background: #265cca;
  padding: 0 20px;
  height: 28px;
  line-height: 28px;
  color: #fff;
  border: 1px solid #cccccc;
  margin-top: 2px;
  cursor: pointer;
  font-size: 14px;
}
.btnzz::after {
  content: ' ';
  float: right;
  width: 16px;
  height: 14px;
  background: url('../../../public/imgs/zz01.png') no-repeat;
  margin-top: 7px;
  margin-left: 8px;
}
.conter_list {
  text-align: left;
  border-top: 2px solid #ccc;
}
.conter_list > li {
  /*border-top: 1px solid #ccc;*/
  border-bottom: 1px solid #ebeef5;
  padding: 20px 0;
}
.conter_list_title {
  font-size: 16px;
  color: #333;
  line-height: 24px;
  cursor: pointer;
}
.conter_list_title input {
  float: left;
  margin: 5px 5px 0 0;
}
.conter_list_more {
  color: #999;
  font-size: 14px;
  line-height: 24px;
}
.conter_list_more li {
  float: left;
  margin-right: 14px;
}
.conter_list_abstract {
  margin-top: 10px;
  color: #666;
  line-height: 24px;
  font-size: 14px;
  text-indent: 28px;
}
.conter_list_keywords {
  margin-top: 10px;
  font-size: 14px;
  color: #999;
  line-height: 18px;
}
.postion2 {
  width: 200px;
  float: right;
  position: relative;
}
.btn {
  position: absolute;
  top: 0;
  right: 0;
}
.curs {
  cursor: pointer;
  position: relative;
}
.curs .el-icon-caret-bottom {
  position: absolute;
  top: 5px;
  left: 90px;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.crumbs {
  position: absolute;
  top: 0px;
  left: 0;
}
.informationAggregation {
  .tableList-search {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .input-with-select {
      width: 400px;
      margin-left: 10px;
    }
  }
}
</style>
<style lang="scss">
.center_2 {
  margin-bottom: 30px;
}
.informationAggregation {
  .conter_list .el-checkbox__label {
    display: none;
  }
  .tableList-search {
    .input-with-select {
      .el-input-group__prepend {
        width: 60px;
      }
    }
  }
}
</style>
