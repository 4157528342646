<!-- mag -->
<template>
  <div class="mag">
    <el-form ref="ruleForm" :model="ruleForm" label-width="130px">
      <!-- 期刊介绍设置 -->
      <div class="part">
        <div class="left-part">
          <img :src="magImg" alt="" />
        </div>
        <div class="right-part editor_box">
          <div class="flex-content">
            <div class="left">
              <el-form-item label="每期封面：" size="zdy4" prop="magCoverId">
                <div class="mag-cover upload-img">
                  <img :src="magCoverSrc" alt />
                  <el-upload class="upload-demo" :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" :auto-upload="true" :on-success="handleCoverSuccess">
                    <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
                  </el-upload>
                </div>
              </el-form-item>
              <el-form-item label="期刊介绍背景图：" size="zdy4" prop="deRotationCoverId">
                <div class="mag-cover upload-img">
                  <img :src="deRotationCoverSrc" alt />
                  <el-upload class="upload-demo" :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" :auto-upload="true" :on-success="handledeRotationCover">
                    <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
                  </el-upload>
                </div>
              </el-form-item>
            </div>
            <div class="right">
              <el-form-item label="期刊介绍标题：" prop="magTitle" size="zdy6">
                <el-input v-model="ruleForm.magTitle" placeholder="自科版字数为6，社科版为12" type="text" :maxlength="maxlength" show-word-limit />
              </el-form-item>
              <el-form-item label="期刊封面URL：" prop="magHref" size="zdy6">
                <el-input v-model="ruleForm.magHref" type="text" />
              </el-form-item>
              <el-form-item label="封面故事介绍：" prop="magHrefInfo" size="zdy6">
                <el-input v-model="ruleForm.magHrefInfo" type="text" />
              </el-form-item>
              <div slot="tip" class="el-upload__tip">
                <el-button v-if="showResetBtn" @click="resetImg" style="margin: 0px 20px 0 0">重置</el-button>
                格式支持：jpg、png、jpeg <br />
                <span :style="showResetBtn ? 'margin-left: 80px' : ''">尺寸推荐：156*231</span>
              </div>
              <div slot="tip" class="el-upload__tip-resetBg">
                <el-button v-if="showResetBtn" @click="resetBgImg" style="margin: 0px 20px 0 0">重置</el-button>
                格式支持：jpg、png、jpeg <br />
              </div>
            </div>
          </div>
          <el-form-item label="期刊介绍内容：" prop="magInfo" size="zdy1">
            <el-tabs type="border-card" v-model="magInfoActiveName">
              <el-tab-pane label="文本框" name="magInfoOne">
                <el-input autosize v-if="magInfoActiveName === 'magInfoOne'" v-model="ruleForm.magInfo" type="textarea" />
              </el-tab-pane>
              <el-tab-pane label="富文本" name="magInfoTwo">
                <editor
                  v-if="magInfoActiveName === 'magInfoTwo'"
                  tinymce-script-src="../../../../../js/tinymce/tinymce.min.js"
                  :init="init"
                  model-events="change keydown blur focus paste"
                  v-model="ruleForm.magInfo"
                />
                <!-- <EditorBar v-if="magInfoActiveName === 'magInfoTwo'" v-model="ruleForm.magInfo"></EditorBar> -->
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { GetPictureStream, UploadPictureStream,uploadEditorImg,getPictureStreamPath } from '@/api/configManage'
// import EditorBar from '@/components/WangEditor/index'
import Editor from '@tinymce/tinymce-vue'
export default {
  name: 'mag',
  data() {
    return {
      uploadEditorImg,
      init: {
        auto_focus: true,
        min_height: 400,
        menubar: false,
        language: 'zh_CN',
        skin: 'CUSTOM',
        content_css: 'CUSTOM',
        convert_urls:false,
        plugins:
          'quickbars print preview searchreplace autolink directionality visualblocks visualchars fullscreen template code codesample charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help link emoticons autosave indent2em lineheight formatpainter image',
        toolbar:
          'fontselect fontsizeselect bullist numlist outdent indent indent2em formatselect  formatpainter bold italic underline strikethrough superscript subscript removeformat forecolor backcolor alignleft aligncenter alignright alignjustify lineheight link undo redo image',
        toolbar_mode: 'sliding',
        quickbars_selection_toolbar: 'bold italic underline strikethrough subscript superscript removeformat',
        quickbars_insert_toolbar: false,
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        font_formats:
          '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;宋体=simsun,serif,sans-serif;仿宋体=FangSong,serif,sans-serif;楷体=KaiTi,serif,sans-serif;黑体=SimHei,sans-serif;隶书=LiSu,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Times New Roman=Times New Roman,helvetica,sans-serif,仿宋_GB2312;',
        images_upload_handler: (blobInfo, success) => {
          this.upload(blobInfo,(e)=>{
            success(e)
          })
        }
      },
      morImg: require('@/assets/img/img_default.png'),
      SKmorImg: require('@/assets/img/bg-periodical.png'),
      GetPictureStream: GetPictureStream,
      UploadPictureStream: UploadPictureStream,
      magCover: '',
      magInfoActiveName: 'magInfoOne',
      deRotationCover: ''
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    magImg: {
      default: require('@/assets/img/template_img/zk/module6.png')
    },
    // 期刊介绍标题限制字数 自科6 社科12
    maxlength: {
      type: Number,
      default: 6
    },
    // 是否显示标题配色
    showMagColor: {
      type: Boolean,
      default: true
    },
    // 是否显示重置按钮
    showResetBtn: {
      type: Boolean,
      default: false
    },
    moduleType: {
      type: String,
      default: ''
    },
    isCommonType: {
      type: Boolean,
      default: false
    }
  },
  components: { Editor },
  watch: {
    magId() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
      })
    }
  },
  computed: {
    //期刊介绍封面
    magCoverSrc() {
      return this.magCover ? this.magCover : this.ruleForm.magCoverId ? `${this.GetPictureStream}?pictureId=${this.ruleForm.magCoverId}` : this.morImg
    },
    //论文默认封面
    deRotationCoverSrc() {
      return this.deRotationCover ? this.deRotationCover : this.ruleForm.deRotationCoverId ? `${this.GetPictureStream}?pictureId=${this.ruleForm.deRotationCoverId}` : this.morImg
    }
  },
  mounted() {},
  methods: {
    upload(blobInfo,fn) {
      let formData = new FormData()
      formData.append('file', blobInfo.blob())
      uploadEditorImg(formData).then(res => {
        if (res.data.status === 0) {
          getPictureStreamPath({pictureId: res.data.data.ID }).then(res1 => {
            let url = res1.data.data.url 
            fn && fn(url) 
          })
        } else {
          this.$message.error('图片上传失败')
          fn && fn('')
        }
      })
    },
    // 成功上传期刊介绍封面
    handleCoverSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.magCover = URL.createObjectURL(file.raw)
      this.ruleForm.magCoverId = ID
      console.log(this.ruleForm.magCoverId, this.ruleForm.magCoverId)
    },
    // 期刊底部取色器色值转换#+16进制
    magColorChange(value) {
      const reg = /[0-9]\d+/g
      const getArr = value.match(reg)
      let hexStr = '#' + ((getArr[0] << 16) | (getArr[1] << 8) | getArr[2]).toString(16)
      this.ruleForm.magTitleColor = hexStr
    },
    // 重置封面图
    resetImg() {
      if (this.moduleType === 'sk') {
        this.magCover = this.SKmorImg
        this.ruleForm.magCoverId = 0
      }
    },
    // 重置背景封面图
    resetBgImg() {
      this.deRotationCover = ''
      this.ruleForm.deRotationCoverId = 0
    },
    // 成功上传论文背景封面
    handledeRotationCover(res, file) {
      const { data } = res
      const { ID } = data
      this.deRotationCover = URL.createObjectURL(file.raw)
      this.ruleForm.deRotationCoverId = ID
    }
  }
}
</script>
<style lang="scss">
.mag {
  .tox .tox-toolbar__group {
    padding: 6px;
    width: 570px;
  }
}
</style>
<style lang="scss" scoped>
.mag {
  .left-part {
    height: 450px;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .mag-cover {
    width: 156px;
    height: 231px;
  }
  .el-upload__tip {
    margin: 0 0 20px 22px;
  }
  .el-upload__tip-resetBg {
    margin: 100px 0 20px 22px;
    text-align: left;
  }
}
</style>
