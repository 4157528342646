<template>
  <div class="w center_all">
    <div class="center_1 posion">
      <h2 class="center_title" v-text="clusterType === 'quote' ? '期刊引用趋势' : '期刊引证趋势'"></h2>
      <div>
        <div id="lineChart" :style="{ width: '100%', height: '570px' }" />
      </div>
      <div>
        <h2 class="center_title" v-text="clusterType === 'quote' ? '引用论文列表' : '引证论文列表'"></h2>
        <!--        <div class="sort clearfix cluster">-->
        <!--          <span class="fl">共计 <span v-text="articlePageObj.total" /> 条记录</span>-->
        <!--          <span class="fl">排序： </span>-->
        <!--          <el-checkbox-group v-model="firstArticleRadio" class="fl" @change="handleSortChange">-->
        <!--            <el-checkbox-button size="small" label="quoteTimes">被引次数</el-checkbox-button>-->
        <!--            <el-checkbox-button size="small" label="firstPubTime">发表时间</el-checkbox-button>-->
        <!--          </el-checkbox-group>-->
        <!--        </div>-->
        <div class="drop-container">
          <span class="tj_year">统计年份:</span>
          <el-select v-model="chooseClusterName" placeholder="请选择" style="top:-4px;right:-10px;">
            <el-option v-for="item in clusterOptionArray" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <div v-if="articleTableData && articleTableData.length > 0" class="table-header-container">
          <div v-text="`《${magName}》论文`"></div>
          <div v-if="clusterType !== 'quote'" class="img-container">
            <img src="./images/arrow.png" alt="" />
          </div>
          <div v-if="clusterType === 'quote'" class="img-container" style="transform: rotate(180deg)">
            <img src="./images/arrow.png" alt="" />
          </div>
          <div v-text="clusterType === 'quote' ? `《${chooseLineName}》被引论文` : `《${chooseLineName}》引用论文`"></div>
        </div>
        <SimpleTable
          ref="articleTable"
          :tableData="articleTableData"
          :columnData="clusterType === 'quote' ? articleColumn : citationArticleColumn"
          :initObj="articlePageObj"
          @onChangePageData="
            (pageObj) => {
              getArticleData(pageObj)
            }
          "
          @onChangeSort="
            (prop, order) => {
              getArticleData({}, prop, order)
            }
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import SimpleTable from '@/components/SimpleTable'
import { mapGetters } from 'vuex'
import { GetClusterMagCitation, GetClusterMagReference, GetReferenceList } from '@/api/cluster'
import { GetMagByName } from '@/api/analysis'
export default {
  name: 'ClusterDetails',
  components: {
    SimpleTable
  },
  mounted() {
    this.searchIds = this.$route.query.searchIds
    this.clusterType = this.$route.query.type
    this.getMagId = this.$route.query.magId
    this.chooseClusterObj = JSON.parse(window.sessionStorage.getItem('chooseClusterObj'))
    this.getLineData()
    this.getChart()
  },
  computed: {
    ...mapGetters({
      magId: 'magId',
      magName: 'platMagName'
    })
  },
  watch: {
    magId(nv) {
      if (this.getMagId !== nv) {
        this.$router.push('/analysis')
      }
    },
    chooseClusterName(nv) {
      this.getMagDataByName(nv)
    }
  },
  data() {
    return {
      chooseClusterObj: {},
      articlePageObj: {
        page: 1,
        size: 10
      },
      articleTableData: [],
      searchIds: '',
      chooseReferenceId: '', // 被引论文的Id
      clusterType: '',
      getMagId: '',
      yearOptions: [
        {
          value: 'count5',
          label: '2016'
        },
        {
          value: 'count4',
          label: '2017'
        },
        {
          value: 'count3',
          label: '2018'
        },
        {
          value: 'count2',
          label: '2019'
        },
        {
          value: 'count1',
          label: '2020'
        }
      ],
      articleColumn: [
        {
          title: '标题',
          prop: 'rootTitle',
          special: true,
          width: '200px',
          notSortable: true
        },
        {
          title: '作者',
          prop: 'rootAuthor',
          notSortable: true
        },
        {
          title: '年份',
          prop: 'rootYear'
        },
        {
          title: '刊期',
          prop: 'rootMagNumber',
          notSortable: true
        },
        {
          title: '标题',
          prop: 'referencedTitle',
          special: true,
          width: '200px',
          notSortable: true
        },
        {
          title: '作者',
          prop: 'referencedAuthor',
          notSortable: true
        },
        {
          title: '年份',
          prop: 'referencedYear'
        },
        {
          title: '刊期',
          prop: 'referencedMagNumber',
          notSortable: true
        }
      ],
      citationArticleColumn: [
        {
          title: '标题',
          prop: 'referencedTitle',
          special: true,
          width: '200px',
          notSortable: true
        },
        {
          title: '作者',
          prop: 'referencedAuthor',
          notSortable: true
        },
        {
          title: '年份',
          prop: 'referencedYear'
        },
        {
          title: '刊期',
          prop: 'referencedMagNumber',
          notSortable: true
        },
        {
          title: '标题',
          prop: 'rootTitle',
          special: true,
          width: '200px',
          notSortable: true
        },
        {
          title: '作者',
          prop: 'rootAuthor',
          notSortable: true
        },
        {
          title: '年份',
          prop: 'rootYear'
        },
        {
          title: '刊期',
          prop: 'rootMagNumber',
          notSortable: true
        }
      ],
      firstArticleRadio: [],
      chooseLineId: '',
      chooseLineName: '',
      clusterOptionArray: [], // 下拉框数据
      chooseClusterName: '' // 下拉框选中项
    }
  },
  methods: {
    getMagDataByName(name = '') {
      if (name && name !== '') {
        GetMagByName({ magName: name }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { id = '' } = data
            if (id !== '') {
              this.chooseLineId = id
              this.chooseLineName = name
              this.getArticleData()
            }
          } else {
            const { message = '' } = res.data
            this.$message.error(message)
          }
        })
      }
    },
    getArticleData(pageParams = {}, prop = '', order = '') {
      const { page = 1, size = 10 } = pageParams
      let sortValue = ''
      if (prop !== '') {
        if (order === 'ascending') {
          sortValue = prop + ',asc'
        } else {
          sortValue = prop + ',desc'
        }
      }
      if (sortValue === '') {
        sortValue = this.articlePageObj.sort || ''
      }
      const params = {
        rootMagId: this.clusterType === 'quote' ? this.magId : this.chooseLineId,
        referencedMagId: this.clusterType === 'quote' ? this.chooseLineId : this.magId,
        page: page - 1,
        size,
        sort: sortValue
      }
      GetReferenceList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.articlePageObj = {
            page,
            size,
            total: totalElements,
            sort: sortValue
          }
          this.articleTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    getLineData() {
      const params = {
        magId: this.getMagId,
        magIds: this.searchIds
      }
      if (this.clusterType === 'citation') {
        GetClusterMagCitation(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { content = [] } = res.data.data
            this.getChart(content, this.chooseClusterObj)
            if (content && content.length && content.length > 0) {
              const firstMagObj = content[0]
              // 初始化下拉框的选择列表
              this.clusterOptionArray = content.map((item) => {
                return {
                  label: item.ctiMagName,
                  value: item.ctiMagName
                }
              })
              const { ctiMagName = '' } = firstMagObj
              this.chooseClusterName = ctiMagName
              // this.getMagDataByName(ctiMagName)
              // if (ctiMagName && ctiMagName !== '') {
              //   GetMagByName({ magName: ctiMagName }).then((res) => {
              //     if (res && res.data && res.data.status === 0) {
              //       const { data } = res.data
              //       const { id = '' } = data
              //       if (id !== '') {
              //         this.chooseLineId = id
              //         this.chooseLineName = ctiMagName
              //         this.getArticleData()
              //       }
              //     } else {
              //       const { message = '' } = res.data
              //       this.$message.error(message)
              //     }
              //   })
              // }
            }
          } else {
            const { message = '' } = res.data
            this.$message.error(message)
          }
        })
      } else {
        GetClusterMagReference(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { content = [] } = res.data.data
            this.getChart(content, this.chooseClusterObj)
            if (content && content.length && content.length > 0) {
              const firstMagObj = content[0]
              // 初始化下拉框的选择列表
              this.clusterOptionArray = content.map((item) => {
                return {
                  label: item.refMagName,
                  value: item.refMagName
                }
              })
              const { refMagName = '' } = firstMagObj
              this.chooseClusterName = refMagName
              // this.getMagDataByName(ctiMagName)
              // if (ctiMagName && ctiMagName !== '') {
              //   GetMagByName({ magName: ctiMagName }).then((res) => {
              //     if (res && res.data && res.data.status === 0) {
              //       const { data } = res.data
              //       const { id = '' } = data
              //       if (id !== '') {
              //         this.chooseLineId = id
              //         this.chooseLineName = ctiMagName
              //         this.getArticleData()
              //       }
              //     } else {
              //       const { message = '' } = res.data
              //       this.$message.error(message)
              //     }
              //   })
              // }
            }
          } else {
            const { message = '' } = res.data
            this.$message.error(message)
          }
        })
      }
    },
    // 折线
    getChart(lineData = [], clusterObj = {}) {
      let that = this
      const myChart = this.$echarts.init(document.getElementById('lineChart'))
      const { clusterName = '' } = clusterObj
      const clusterData = this.yearOptions.map((item, index) => {
        return index !== this.yearOptions.length - 1 ? clusterObj[item.value] : '-'
      })
      const clusterDotData = this.yearOptions.map((item, index) => {
        return index < this.yearOptions.length - 2 ? '-' : clusterObj[item.value]
      })
      const clusterSeriesData = {
        name: clusterName,
        clusterType: 'cluster',
        lineType: 'origin',
        type: 'line',
        data: clusterData
      }
      const clusterDotSeriesData = {
        name: clusterName,
        clusterType: 'cluster',
        lineType: 'dot',
        type: 'line',
        smooth: false, //关键点，为true是不支持虚线，实线就用true
        itemStyle: {
          normal: {
            lineStyle: {
              // width: 2,
              type: 'dotted' //'dotted'虚线 'solid'实线
            }
          }
        },
        data: clusterDotData
      }
      const legendData = [clusterName]
      const seriesData = [clusterSeriesData, clusterDotSeriesData]
      const xData = this.yearOptions.map((item) => {
        return item.label
      })
      for (let i = 0; i < lineData.length; i += 1) {
        legendData.push(this.clusterType === 'citation' ? lineData[i].ctiMagName : lineData[i].refMagName)
        const magData = this.yearOptions.map((item, index) => {
          return index !== this.yearOptions.length - 1 ? lineData[i][item.value] : '-'
        })
        const magDotData = this.yearOptions.map((item, index) => {
          return index < this.yearOptions.length - 2 ? '-' : lineData[i][item.value]
        })
        const tempSeries = {
          name: this.clusterType === 'citation' ? lineData[i].ctiMagName : lineData[i].refMagName,
          clusterType: 'mag',
          lineType: 'origin',
          type: 'line',
          data: magData
        }
        const tempDotSeries = {
          name: this.clusterType === 'citation' ? lineData[i].ctiMagName : lineData[i].refMagName,
          clusterType: 'mag',
          lineType: 'dot',
          type: 'line',
          smooth: false, //关键点，为true是不支持虚线，实线就用true
          itemStyle: {
            normal: {
              lineStyle: {
                type: 'dotted' //'dotted'虚线 'solid'实线
              }
            }
          },
          data: magDotData
        }
        seriesData.push(tempSeries)
        seriesData.push(tempDotSeries)
      }
      myChart.setOption({
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xData,
          axisLabel: {
            interval: 0
            // rotate: 60
          }
        },
        yAxis: {
          type: 'value',
          minInterval: 1
        },
        tooltip: {
          trigger: 'axis',
          formatter: function(paramData) {
            const { dataIndex = 0 } = paramData[0] || {}
            let filterData = []
            if (dataIndex || dataIndex === 0) {
              if (dataIndex < that.yearOptions.length - 2) {
                filterData = paramData.filter((item, index) => {
                  return !(index % 2)
                })
              } else {
                filterData = paramData.filter((item, index) => {
                  return index % 2
                })
              }
            }
            // console.log('paramData', paramData)
            let domString = '<ul style="text-align: left;font-size: 12px">'
            for (let i = 0; i < filterData.length; i += 1) {
              domString += `
                <li>${filterData[i].seriesName}:${filterData[i].value}</li>
              `
            }
            domString += '</ul>'
            return domString
          }
        },
        legend: {
          y: 'top',
          x: 'center',
          data: legendData
        },
        series: seriesData
      })
      if (myChart._$handlers.click) {
        myChart._$handlers.click.length = 0
      }
      myChart.on('click', function(param) {
        const { seriesName = '', seriesIndex = '' } = param
        if (seriesIndex !== 0 && seriesIndex !== 1 && seriesName && seriesName !== '') {
          GetMagByName({ magName: seriesName }).then((res) => {
            if (res && res.data && res.data.status === 0) {
              const { data } = res.data
              const { id = '' } = data
              if (id !== '') {
                that.chooseLineId = id
                that.chooseLineName = seriesName
                that.chooseClusterName = seriesName
                that.getArticleData()
              }
            } else {
              const { message = '' } = res.data
              that.$message.error(message)
            }
          })
        }
      })
    }
  }
}
</script>
<style>
.cluster {
  display: flex;
  align-items: center;
}
.table-header-container {
  padding: 20px 0;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}
.table-header-container .img-container {
  width: 10%;
}
.table-header-container div {
  display: inline-block;
  width: 45%;
}
.drop-container {
  text-align: right;
  padding: 0 20px;
}
</style>
