<template>
  <div class="w center_all">
    <div class="center_1">
      <!--      style="display: none">-->
      <!-- 期刊详情 -->
      <div class="pt-10 disDiv mb-30">
        <!--        <div class="fengm">-->
        <!--          <img src="../../../public/imgs/fm.jpg" alt="" />-->
        <!--        </div>-->
        <div class="detail">
          <div class="title_z" v-text="detailObj.name"></div>
          <div class="title_e" v-text="detailObj.enName"></div>
          <div class="conter_4 mb-3">
            <ul class="ul_1">
              <!--<li><p>邮件推送共推送用户:</p><span>2000</span></li>-->
              <!--<li><p>点击连接用户数量:</p><span>2000</span></li>-->
              <!--<li><p>激活用户数量:</p><span>1000</span></li>-->
              <li>
                <p>曾用名：</p>
                <span v-text="detailObj.usedName"></span>
              </li>
              <li>
                <p>主办单位：</p>
                <span v-text="detailObj.org"></span>
              </li>
              <li>
                <p>出版周期：</p>
                <span v-text="detailObj.publishPeriod"></span>
              </li>
              <li>
                <p>语种：</p>
                <span v-text="detailObj.language"></span>
              </li>
              <li>
                <p>ISSN：</p>
                <span v-text="detailObj.issn"></span>
              </li>
              <li>
                <p>CN：</p>
                <span v-text="detailObj.cn"></span>
              </li>
            </ul>
            <ul class="ul_2">
              <li>
                <p>影响因子：</p>
                <span v-text="`知网：${detailObj.influence1} 万方：${detailObj.influence2} 百度：${detailObj.influence3}`">知网1.053</span>
              </li>
              <li>
                <p>出版地：</p>
                <span v-text="detailObj.publishRegion"></span>
              </li>
              <li>
                <p>语种：</p>
                <span v-text="detailObj.language">65029</span>
              </li>
              <li>
                <p>开本：</p>
                <span v-text="detailObj.bookSize">11235</span>
              </li>
              <li>
                <p>邮发代号：</p>
                <span v-text="detailObj.postCode">1846</span>
              </li>
              <li>
                <p>创刊时间：</p>
                <span v-text="detailObj.journalCreateTime">1846</span>
              </li>
            </ul>
            <ul class="ul_3">
              <li><p>该刊被以下数据库收录：</p></li>
              <li v-for="item in includedDatabasesArr" :key="item">
                <span>{{ item }}</span>
              </li>
              <li>
                <p>北京大学《中文核心期刊要目总揽》来源期刊：</p>
                <span v-text="detailObj.journalHonorSource"></span>
              </li>
              <li>
                <p>期刊荣誉：</p>
                <span v-text="detailObj.journalHonor"></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 期刊概况 -->
      <div>
        <h2 class="center_title">期刊概况</h2>
        <div class="conter_1">
          <div v-for="(item, index) in dictionary" :key="index" :class="item.number || item.number === 0 ? 'con_01 cuser01' : 'con_01'" @click="goRoute(item)">
            <NumRoll v-if="item.number" :numbers="item.number + ''" :colors="colors[index]" />
            <div class="con_01_title">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <!-- 引证图谱 -->
      <div>
        <h2 class="center_title">
          引证图谱
          <div class="fr btn sdClias">
            <el-radio-group v-model="chooseYearRange">
              <el-radio-button v-for="item in timeOptions" :key="item.key" :label="item.key">{{ item.value }}</el-radio-button>
            </el-radio-group>
            <span class="tj_year">统计年份:</span>
            <el-select v-model="chooseYearVal" placeholder="请选择" style="width:100px;top:-4px;right:-10px;">
              <el-option v-for="item in yearOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
        </h2>
        <div class="conter_1" style="width:100%;position: relative">
          <div class="postion btn">
            <span class="tj_year">前</span>
            <el-select v-model="quoteVal" placeholder="请选择" style="width:70px;top:-4px;right:-10px;">
              <el-option v-for="item in rankOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <span class="tj_year1">个</span>
          </div>
          <div class="postion1 btn">
            <span class="tj_year">前</span>
            <el-select v-model="citationVal" placeholder="请选择" style="width:70px;top:-4px;right:-10px;">
              <el-option v-for="item in rankOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <span class="tj_year1">个</span>
          </div>
          <div id="mycharts" :style="{ width: '100%', height: '420px', background: '#f5f7fd', padding: '20px 0' }" />
        </div>
      </div>
      <!-- 引证统计 -->
      <div>
        <h2 class="center_title">
          引证统计
          <div class="fr btn">
            <el-radio-group v-model="citationTimeValue">
              <el-radio-button v-for="item in timeOptions" :key="item.key" :label="item.key">{{ item.value }}</el-radio-button>
            </el-radio-group>
            <el-button
              type="text"
              icon="el-icon-xz"
              @click="
                () => {
                  handleDownload('quote')
                }
              "
              >下载</el-button
            >
          </div>
        </h2>
        <SimpleTable
          ref="citationTable"
          :tableData="citationTableData"
          :columnData="citationTimeValue === 3 ? citationColumnThree : citationColumn"
          :initObj="citationPageObj"
          @onChangeSort="
            (prop, order) => {
              handleChangeSort(prop, order, 'citation')
            }
          "
          @onChangePageData="
            (pageObj) => {
              getCitationData(pageObj)
            }
          "
        />
      </div>
      <!-- 引用统计  -->
      <div>
        <h2 class="center_title">
          引用统计
          <div class="fr btn">
            <el-radio-group v-model="quoteTimeValue">
              <el-radio-button v-for="item in timeOptions" :key="item.key" :label="item.key">{{ item.value }}</el-radio-button>
            </el-radio-group>
            <el-button
              type="text"
              icon="el-icon-xz"
              @click="
                () => {
                  handleDownload('quote')
                }
              "
              >下载</el-button
            >
          </div>
        </h2>
        <SimpleTable
          ref="quoteTable"
          :tableData="quoteTableData"
          :columnData="quoteTimeValue === 3 ? quoteColumnThree : quoteColumn"
          :initObj="quotePageObj"
          @onChangeSort="
            (prop, order) => {
              handleChangeSort(prop, order, 'quote')
            }
          "
          @onChangePageData="
            (pageObj) => {
              getReferenceData(pageObj)
            }
          "
        />
      </div>
      <!-- 机构发文统计-->
      <div id="magInstitutionCount">
        <h2 class="center_title">
          机构发文统计
          <div class="fr btn">
            <el-select v-model="institutionTypeValue" placeholder="请选择" style="width:100px;top:-4px;">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <el-radio-group v-model="institutionTimeValue">
              <el-radio-button v-for="item in timeOptions" :key="item.key" :label="item.key">{{ item.value }}</el-radio-button>
            </el-radio-group>
            <el-button
              type="text"
              icon="el-icon-xz"
              @click="
                () => {
                  handleDownload('institution')
                }
              "
              >下载</el-button
            >
          </div>
        </h2>
        <SimpleTable
          ref="institutionTable"
          :tableData="institutionTableData"
          :columnData="institutionTimeValue === 3 ? institutionColumnThree : institutionColumn"
          :initObj="institutionPageObj"
          @onChangeSort="
            (prop, order) => {
              handleChangeSort(prop, order, 'institution')
            }
          "
          @onChangePageData="
            (pageObj) => {
              getInstitutionData(pageObj)
            }
          "
        />
      </div>
      <!-- 论文引证统计 -->
      <div id="magArticleCount">
        <h2 class="center_title">
          论文引证统计
          <div class="fr btn">
            <el-select v-model="articleTypeValue" placeholder="请选择" style="width:100px;top:-4px;">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <el-button
              type="text"
              icon="el-icon-xz"
              @click="
                () => {
                  handleDownload('article')
                }
              "
              >下载</el-button
            >
          </div>
        </h2>
        <SimpleTable
          ref="articleTable"
          :tableData="articleTableData"
          :columnData="articleColumn"
          :initObj="articlePageObj"
          @onChangeSort="
            (prop, order) => {
              handleChangeSort(prop, order, 'article')
            }
          "
          @onChangePageData="
            (pageObj) => {
              getArticleData(pageObj)
            }
          "
          @onClickTitle="handleJumpUrl"
        />
      </div>
    </div>
    <!--    <img-->
    <!--      style="position: absolute; top: 0; left: 0; z-index: 1000"-->
    <!--      src="../../static/imgs/xq.jpg"-->
    <!--      alt="">-->
  </div>
</template>
<script>
import { baseUrl } from '@/assets/js/base64'
import { mapGetters } from 'vuex'
import NumRoll from '@/components/NumRoll'
import SimpleTable from '@/components/SimpleTable'
import {
  GetReference,
  GetCitation,
  exportCitation,
  exportReference,
  GetCitationGraph,
  GetQuoteGraph,
  GetAllCitationQuote,
  GetMagByName,
  GetInstitution,
  GetArticle,
  exportInstitution,
  exportArticle
} from '@/api/analysis'
import { GetMagDetail, GetMagOverView } from '@/api/detail'
import { formatNum } from '@/utils'
export default {
  components: {
    NumRoll,
    SimpleTable
  },
  computed: {
    ...mapGetters({
      magId: 'magId'
    })
  },
  data() {
    return {
      getMagId: '', // 传过来时选择的magId
      chooseId: '', // 从上个页面传过来的
      detailObj: {}, // 详情对象
      dictionary: [
        { key: 'articleTotalCount', title: '总发文量' },
        { key: 'referenceTotalCount', title: '总被引量' },
        { key: 'referenceAverageCount', title: '平均被引量' },
        { key: 'referenceSingleMax', title: '单篇最高被引量' }
      ],
      colors: ['#ec5463', '#eea806', '#6eb91a', '#2db897'], // 期刊领域概况 颜色
      // 引证图谱
      chooseYearRange: 5,
      rankOptions: [
        {
          value: '5',
          label: '5'
        },
        {
          value: '10',
          label: '10'
        }
      ],
      // 近几年选项的options
      timeOptions: [
        {
          key: 5,
          value: '近5年'
        },
        {
          key: 3,
          value: '近3年'
        }
      ],
      yearOptions: [
        {
          value: 'count1',
          label: '2020'
        },
        {
          value: 'count2',
          label: '2019'
        },
        {
          value: 'count3',
          label: '2018'
        },
        {
          value: 'count4',
          label: '2017'
        },
        {
          value: 'count5',
          label: '2016'
        }
      ],
      chooseYearVal: 'count1',
      citationTableData: [],
      citationVal: '5',
      citationPageObj: {
        page: 1,
        size: 10,
        sort: 'totalCount5,desc'
      }, // 引证统计分页
      citationTimeValue: 5, // 当前 引证统计 时间 选中项
      citationColumn: [
        {
          title: '引证刊名',
          prop: 'ctiMagName',
          width: '300px',
          notSortable: true
        },
        ...recentFiveYear,
        {
          title: '合计',
          prop: 'totalCount5'
        }
      ], // 引证统计 表格header 内容（近五年）
      citationColumnThree: [
        {
          title: '引证刊名',
          prop: 'ctiMagName',
          width: '300px',
          notSortable: true
        },
        ...recentThreeYear,
        {
          title: '合计',
          prop: 'totalCount3'
        }
      ], // 引证统计 表格header 内容（近三年）
      // 被引统计
      quoteTimeValue: 5, // 当前 引用统计 时间 选中项
      quoteTableData: [],
      quoteVal: '5',
      quotePageObj: {
        page: 1,
        size: 10,
        sort: 'totalCount5,desc'
      },
      quoteColumnThree: [
        {
          title: '引用刊名',
          prop: 'refMagName',
          width: '300px',
          notSortable: true
        },
        ...recentThreeYear,
        {
          title: '合计',
          prop: 'totalCount3'
        }
      ], // 引用统计 表格header 内容(近三年)
      quoteColumn: [
        {
          title: '引用刊名',
          prop: 'refMagName',
          width: '300px',
          notSortable: true
        },
        ...recentFiveYear,
        {
          title: '合计',
          prop: 'totalCount5'
        }
      ], // 引用统计 表格header 内容(近五年)
      chooseDataKey: 'totalCount5',
      // 发文机构统计
      institutionTimeValue: 5, // 当前 发文机构统计 时间筛选 选中项
      institutionPageObj: {
        page: 1,
        size: 10,
        sort: 'totalCount5,desc'
      },
      institutionTableData: [],
      institutionColumn: [
        {
          title: '发文机构',
          prop: 'institutionName',
          width: '190px',
          notSortable: true
        },
        ...recentFiveYear,
        {
          title: '合计',
          prop: 'totalCount5'
        },
        {
          title: '平均被引',
          prop: 'referedCount5'
        },
        {
          title: '单篇最高被引',
          width: '150px',
          prop: 'singleReferedCount5'
        }
      ], // 发文机构统计 表格header 内容（近五年）
      institutionColumnThree: [
        {
          title: '发文机构',
          prop: 'institutionName',
          width: '300px',
          notSortable: true
        },
        ...recentThreeYear,
        {
          title: '合计',
          prop: 'totalCount3'
        },
        {
          title: '平均被引',
          prop: 'referedCount3'
        },
        {
          title: '单篇最高被引',
          width: '150px',
          prop: 'singleReferedCount3'
        }
      ], // 发文机构统计 表格header 内容（近三年）
      // 发文统计和引证统计的option选项
      options: [
        {
          value: 'mag',
          label: '本刊'
        },
        {
          value: 'domain',
          label: '本领域'
        }
      ],
      institutionTypeValue: 'mag',
      // 论文引证统计
      articlePageObj: {
        page: 1,
        size: 10
      },
      articleTableData: [],
      radio5: '近3年', // 当前 论文引证统计 选中项
      articleColumn: [
        {
          title: '论文标题',
          prop: 'articleName',
          special: true,
          width: '300px',
          notSortable: true
        },
        {
          title: '刊物名称',
          prop: 'magName',
          notSortable: true
        },
        {
          title: '作者',
          prop: 'author',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'institution',
          notSortable: true
        },
        {
          title: '年份',
          prop: 'year'
        },
        {
          title: '刊期',
          prop: 'issue',
          notSortable: true
        },
        {
          title: '引证次数',
          prop: 'totalCount'
        }
      ], // 论文引证统计 表格header 内容
      articleTypeValue: 'mag',
      includedDatabasesArr: []
    }
  },
  watch: {
    magId(nv) {
      if (this.getMagId !== nv) {
        this.$router.push('/analysis')
      }
    },
    articleTypeValue() {
      this.getArticleData()
      this.$refs.articleTable.clearSort()
    },
    institutionTypeValue() {
      this.getInstitutionData()
      this.$refs.institutionTable.clearSort()
    },
    institutionTimeValue(nv) {
      this.institutionPageObj.sort = nv === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
      this.getInstitutionData()
      this.$refs.institutionTable.clearSort()
    },
    citationTimeValue(nv) {
      this.citationPageObj.sort = nv === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
      this.getCitationData()
      this.$refs.citationTable.clearSort()
    },
    quoteTimeValue(nv) {
      this.quotePageObj.sort = nv === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
      this.getReferenceData()
      this.$refs.quoteTable.clearSort()
    },
    citationVal() {
      this.echart()
    },
    quoteVal() {
      this.echart()
    },
    chooseYearVal(nv) {
      this.chooseDataKey = nv
      // 选择的时间之后，将三五年清除选中
      this.chooseYearRange = 0
      const citationPromise = this.getCitationGraphData()
      const quotePromise = this.getQuoteGraphData()
      Promise.all([citationPromise, quotePromise]).then(([res1, res2]) => {
        if (res1 && res2) {
          // 更新echarts图
          this.echart()
        }
      })
    },
    chooseYearRange(nv) {
      if (nv === 3) {
        this.chooseDataKey = 'totalCount3'
      } else if (nv === 5) {
        this.chooseDataKey = 'totalCount5'
      }
      if (nv !== 0) {
        const citationPromise = this.getCitationGraphData()
        const quotePromise = this.getQuoteGraphData()
        Promise.all([citationPromise, quotePromise]).then(([res1, res2]) => {
          if (res1 && res2) {
            // 更新echarts图
            this.echart()
          }
        })
      }
    },
    chooseId() {
      this.getMagDetail()
      this.getMagOverView()
      this.getCitationData()
      this.getReferenceData()
      const citationPromise = this.getCitationGraphData()
      const quotePromise = this.getQuoteGraphData()
      const totalPromise = this.getCitationQuoteData()
      Promise.all([citationPromise, quotePromise, totalPromise]).then(([res1, res2, res3]) => {
        if (res1 && res2 && res3) {
          // 更新echarts图
          this.echart()
        }
      })
    }
  },
  mounted() {
    this.chooseId = this.$route.query.searchId
    this.getMagId = this.$route.query.searchId
    this.getMagDetail()
    this.getMagOverView()
    this.getCitationData()
    this.getReferenceData()
    this.getInstitutionData()
    this.getArticleData()
    const citationPromise = this.getCitationGraphData()
    const quotePromise = this.getQuoteGraphData()
    const totalPromise = this.getCitationQuoteData()
    Promise.all([citationPromise, quotePromise, totalPromise]).then(([res1, res2, res3]) => {
      if (res1 && res2 && res3) {
        // 更新echarts图
        this.echart()
      }
    })
  },
  methods: {
    // 跳转论文详情页
    handleJumpUrl(chooseItem) {
      const { articleId = '' } = chooseItem
      if (articleId && articleId !== '') {
        this.$router.push({ path: '/articleDetails', query: { searchId: articleId } })
      }
    },
    // 获取引证和引用中间球的数据
    getCitationQuoteData() {
      const params = {
        id: this.chooseId
      }
      return GetAllCitationQuote(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.totalData = data
          return true
        } else {
          const { message } = res.data
          this.$message.error(message)
          return false
        }
      })
    },
    // 获取引证图谱中引用数据
    getQuoteGraphData() {
      let sortVal = ''
      if (this.chooseYearRange === 0) {
        sortVal = `${this.chooseYearVal},desc`
      } else {
        sortVal = `totalCount${this.chooseYearRange},desc`
      }
      const params = {
        magId: this.chooseId,
        page: 0,
        size: 10,
        sort: sortVal
      }
      return GetQuoteGraph(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.quoteList = data
          this.quoteList.reverse()
          return true
        } else {
          const { message } = res.data
          this.$message.error(message)
          return false
        }
      })
    },
    // 获取引证图谱中引证数据
    getCitationGraphData() {
      let sortVal = ''
      if (this.chooseYearRange === 0) {
        sortVal = `${this.chooseYearVal},desc`
      } else {
        sortVal = `totalCount${this.chooseYearRange},desc`
      }
      const params = {
        magId: this.chooseId,
        page: 0,
        size: 10,
        sort: sortVal
      }
      return GetCitationGraph(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.citationList = data
          this.citationList.reverse()
          return true
        } else {
          const { message } = res.data
          this.$message.error(message)
          return false
        }
      })
    },
    // 处理所有下载的函数
    handleDownload(downloadType = '') {
      if (downloadType === 'citation') {
        window.open(`${exportCitation}?magId=${this.chooseId}&page=${this.citationPageObj.page - 1}&size=${this.citationPageObj.size}&sort=${this.citationPageObj.sort || ''}`, '_self')
        return
      }
      if (downloadType === 'quote') {
        window.open(`${exportReference}?magId=${this.chooseId}&page=${this.quotePageObj.page - 1}&size=${this.quotePageObj.size}&sort=${this.quotePageObj.sort || ''}`, '_self')
        return
      }
      if (downloadType === 'institution') {
        window.open(
          `${exportInstitution}?type=${this.institutionTypeValue}&magId=${this.magId}&page=${this.institutionPageObj.page - 1}&size=${this.institutionPageObj.size}&sort=${this.institutionPageObj
            .sort || ''}`,
          '_self'
        )
        return
      }
      if (downloadType === 'article') {
        window.open(
          `${exportArticle}?type=${this.articleTypeValue}&magId=${this.magId}&page=${this.articlePageObj.page - 1}&size=${this.articlePageObj.size}&sort=${this.articlePageObj.sort || ''}&type=${
            this.articleTypeValue
          }`,
          '_self'
        )
      }
    },
    // 排序的改变了
    handleChangeSort(prop, order, tableName) {
      if (tableName === 'quote') {
        // 引用统计列表排序改变
        let sortValue = ''
        if (!order || order === '') {
          sortValue = this.quoteTimeValue === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
        } else {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.quotePageObj.sort = sortValue
        this.getReferenceData()
      } else if (tableName === 'citation') {
        // 引证统计列表排序改变
        let sortValue = ''
        if (!order || order === '') {
          sortValue = this.citationTimeValue === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
        } else {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.citationPageObj.sort = sortValue
        this.getCitationData()
      } else if (tableName === 'institution') {
        // 机构发文统计排序改变
        let sortValue = ''
        if (!order || order === '') {
          sortValue = this.institutionTimeValue === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
        } else {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.institutionPageObj.sort = sortValue
        this.getInstitutionData()
      } else if (tableName === 'article') {
        // 论文引证统计统计排序改变
        let sortValue = ''
        if (order && order !== '') {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.articlePageObj.sort = sortValue
        this.getArticleData()
      }
    },
    // 获取引证统计数据
    async getCitationData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.chooseId,
        page: page - 1,
        size,
        sort: this.citationPageObj.sort
      }
      GetCitation(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.citationPageObj = {
            ...this.citationPageObj,
            page,
            size,
            total: totalElements
          }
          this.citationTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取引用统计数据
    async getReferenceData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.chooseId,
        page: page - 1,
        size,
        sort: this.quotePageObj.sort
      }
      GetReference(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.quotePageObj = {
            ...this.quotePageObj,
            page,
            size,
            total: totalElements
          }
          this.quoteTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 跳转
    goRoute(val) {
      if (val.number) {
        console.log(val, '跳转')
      } else {
        this.$message.error('暂无数据')
        console.log(val, '不跳')
      }
    },
    // 期刊详情查询
    getMagDetail() {
      const params = {
        id: this.chooseId
      }
      GetMagDetail(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.includedDatabasesArr = data.includedDatabases ? JSON.parse(data.includedDatabases) : []
          this.$set(this, 'detailObj', data)
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取期刊统计概览信息
    getMagOverView() {
      const params = {
        id: this.chooseId
      }
      GetMagOverView(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data = {} } = res.data
          const newArray = this.dictionary.map((item) => {
            return {
              ...item,
              number: formatNum(data[item.key])
            }
          })
          this.$set(this, 'dictionary', newArray)
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 发文机构统计数据请求
    getInstitutionData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.chooseId,
        page: page - 1,
        size,
        type: this.institutionTypeValue,
        sort: this.institutionPageObj.sort
      }
      GetInstitution(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.institutionPageObj = {
            ...this.institutionPageObj,
            page,
            size,
            total: totalElements
          }
          this.institutionTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 论文引证统计数据请求
    getArticleData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.chooseId,
        page: page - 1,
        size,
        type: this.articleTypeValue,
        sort: this.articlePageObj.sort
      }
      GetArticle(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.articlePageObj = {
            ...this.articlePageObj,
            page,
            size,
            total: totalElements
          }
          this.articleTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 引用图谱
    echart() {
      let jnL // 站位假数据
      let jnR // 站位假数据
      let dxDataL //左侧宽高
      let dxDataR //右侧宽高
      let numsL // 左侧个数
      let numsR // 右侧个数
      let zbDataL = [] // 左侧坐标
      let zbDataR = [] // 右侧坐标
      if (this.quoteVal === '5') {
        // 5 个的宽高
        dxDataL = [180, 48] // 宽 高
        numsL = 5
        jnL = 5
      } else {
        // 10 个的宽高
        dxDataL = [180, 24] // 宽 高
        numsL = 10
        jnL = 10
      }
      if (this.citationVal === '5') {
        // 5 个的宽高
        dxDataR = [180, 48] // 宽 高
        numsR = 5
        jnR = 5
      } else {
        // 10 个的宽高
        dxDataR = [180, 24] // 宽 高
        numsR = 10
        jnR = 10
      }
      // 左 的坐标算法
      for (let i = 0; i < numsL; i++) {
        zbDataL.push([150, i * (560 / (numsL - 1))])
      }
      // 右  的坐标算法
      for (let i = 0; i < numsR; i++) {
        zbDataR.push([850, i * (560 / (numsR - 1))])
      }
      // 随机数
      let randomNumBoth = function(maxNum, minNum, decimalNum) {
        let max = 0
        let min = 0
        // eslint-disable-next-line no-unused-expressions
        minNum <= maxNum ? ((min = minNum), (max = maxNum)) : ((min = maxNum), (max = minNum))
        switch (arguments.length) {
          case 1:
            return Math.floor(Math.random() * (max + 1))
          case 2:
            return Math.floor(Math.random() * (max - min + 1) + min)
          case 3:
            return (Math.random() * (max - min) + min).toFixed(decimalNum)
          default:
            return Math.random()
        }
      }
      let citationList = this.citationList.filter((item) => {
        return item[this.chooseDataKey] !== 0 && item[this.chooseDataKey] !== '0'
      }) //右侧 引证
      let quoteList = this.quoteList.filter((item) => {
        return item[this.chooseDataKey] !== 0 && item[this.chooseDataKey] !== '0'
      }) //左侧 引用
      citationList = citationList.slice(this.citationList.length - (this.citationVal - 0) >= 0 ? this.citationList.length - (this.citationVal - 0) : 0, this.citationList.length)
      quoteList = quoteList.slice(this.quoteList.length - (this.quoteVal - 0) >= 0 ? this.quoteList.length - (this.quoteVal - 0) : 0, this.quoteList.length)
      const citationGraphList = [] // 右侧引证数据
      const quoteGraphList = [] // 左侧引用数据
      const linkArray = [] // 线的数据
      //左侧
      for (let i = 0; i < quoteList.length; i += 1) {
        const quoteItem = {
          id: i + 1,
          searchName: quoteList[i].refMagName,
          name: quoteList[i].refMagName,
          x: quoteList[i][this.chooseDataKey], // 本刊引用
          y: [[quoteList[i].influence1 || 0, quoteList[i].influence2 || 0, quoteList[i].influence3 || 0], randomNumBoth(1000, 2000)], // 影响因子 + 发行量
          fixed: true,
          value: zbDataL[i], // 坐标
          symbolSize: quoteList[0].ctiMagName === 'zwd99' ? '' : dxDataL,
          label: {
            fontSize: 14,
            color: '#666',
            formatter: function(value) {
              var res = value.name
              if (res.length > 12) {
                res = res.substring(0, 11) + '..'
              }
              return res
            }
          },
          category: 2,
          itemStyle: {
            normal: {
              borderType: 'solid',
              borderColor: '#e7e7e7',
              borderWidth: 1,
              color: '#fff'
            }
          }
        }
        const tempVal = quoteList[i][this.chooseDataKey]
        const linkItem = {
          source: i + 1 + '',
          target: '0',
          label: {
            show: true,
            formatter: function(item) {
              return tempVal // 数值
            },
            color: '#666',
            align: 'center',
            verticalAlign: 'middle'
          },
          lineStyle: {
            normal: {
              // color: "#12b5d0",
            }
          }
        }
        quoteGraphList.push(quoteItem)
        if (quoteList[0].ctiMagName === 'zwd99') {
        } else {
          linkArray.push(linkItem)
        }
      }
      //右侧
      for (let i = 0; i < citationList.length; i += 1) {
        const citationItem = {
          id: i + 1 + quoteList.length,
          searchName: citationList[i].ctiMagName,
          name: citationList[i].ctiMagName === 'zwd99' ? '' : citationList[i].ctiMagName,
          x: citationList[i][this.chooseDataKey], // 本刊引用
          y: [[citationList[i].influence1 || 0, citationList[i].influence2 || 0, citationList[i].influence3 || 0], randomNumBoth(1000, 2000)], // 影响因子 + 发行量
          fixed: true,
          value: zbDataR[i], // 坐标
          symbolSize: citationList[0].ctiMagName === 'zwd99' ? '' : dxDataR,
          label: {
            fontSize: 14,
            color: '#666',
            formatter: function(value) {
              var res = value.name
              if (res.length > 12) {
                res = res.substring(0, 11) + '..'
              }
              return res
            }
          },
          category: 1,
          itemStyle: {
            normal: {
              borderType: 'solid',
              borderColor: '#e7e7e7',
              borderWidth: 1,
              color: '#fff'
            }
          }
        }
        const tempVal = citationList[i][this.chooseDataKey]
        const linkItem = {
          source: '0',
          target: i + 1 + quoteList.length + '',
          label: {
            show: true,
            formatter: function(item) {
              return tempVal // 数值
            },
            color: '#666',
            align: 'center',
            verticalAlign: 'middle'
          },
          lineStyle: {
            normal: {
              // color: "#12b5d0",
            }
          }
        }
        citationGraphList.push(citationItem)
        if (citationList[0].ctiMagName === 'zwd99') {
        } else {
          linkArray.push(linkItem)
        }
      }
      let zdxData = []
      // 左侧 动效 坐标数据
      for (let i = 0; i < quoteList.length; i++) {
        if (quoteList[0].refMagName === 'zwd99') {
        } else {
          zdxData.push({
            coords: [zbDataL[i], [500, 280]]
          })
        }
      }
      let rdxData = []
      // 右侧 动效 坐标数据
      for (let i = 0; i < citationList.length; i++) {
        if (citationList[0].ctiMagName === 'zwd99') {
        } else {
          rdxData.push({
            coords: [[500, 280], zbDataR[i]]
          })
        }
      }
      const dxData = zdxData.concat(rdxData)
      //中间数据
      const data = [
        // 中间的
        {
          id: 0,
          name: this.totalData.name,
          tooltip: {
            formatter: function(item) {
              if (item && item.value) {
                let name = item.data.name || ''
                let data0 = item.data.y ? item.data.y[0][0] : ''
                let data1 = item.data.y ? item.data.y[0][1] : ''
                let data2 = item.data.y ? item.data.y[0][2] : ''
                let data3 = item.data.y ? item.data.y[0][3] : ''
                return `<ul style="text-align: left;font-size: 12px">
                        <li>${name}</li>
                        <li>影响因子：知网：${data0} 万方：${data1} 百度：${data2}</li>
                        <li>ISSN号：${data3}</li>
                      </ul>
               `
              }
            }
          },
          symbolSize: [250, 250],
          value: [500, 280],
          x: 752,
          y: [[this.totalData.influence1 || 0, this.totalData.influence2 || 0, this.totalData.influence3 || 0, this.totalData.issn || ''] || 0, randomNumBoth(1000, 2000)], // 影响因子 + 发行量
          symbol: baseUrl,
          fixed: true,
          // draggable: false,
          category: 1,
          label: {
            fontSize: 20,
            color: '#fff'
          }
        },
        ...quoteGraphList,
        ...citationGraphList
      ]
      let that = this
      // 暂时不展示的内容
      // <li>本刊引用：${item.data.x} 总被引:${item.data.x}</li>
      // <li>发文量：${item.data.y[1]}</li>
      // <li>ISSN号:1231321</li>
      let myChart = this.$echarts.init(document.getElementById('mycharts'))
      myChart.setOption(
        {
          backgroundColor: '#f5f7fd',
          xAxis: {
            show: false,
            type: 'value'
          },
          yAxis: {
            show: false,
            type: 'value'
          },
          tooltip: {
            formatter: function(item) {
              if (item && item.value) {
                let name = item.data.name || ''
                let data0 = item.data.y ? item.data.y[0][0] : ''
                let data1 = item.data.y ? item.data.y[0][1] : ''
                let data2 = item.data.y ? item.data.y[0][2] : ''
                return `<ul style="text-align: left;font-size: 12px">
                          <li>${name}</li>
                          <li>影响因子：知网：${data0} 万方：${data1} 百度：${data2}</li>
                        </ul>
                 `
              }
            }
          },
          series: [
            {
              type: 'graph',
              zlevel: 5,
              draggable: false,
              coordinateSystem: 'cartesian2d', // 使用二维的直角坐标系（也称笛卡尔坐标系）
              symbol: 'rect',
              // symbolOffset: ['15%', 0],
              label: {
                normal: {
                  show: true
                }
              },
              data: data,
              links: linkArray,
              lineStyle: {
                normal: {
                  opacity: 1,
                  color: '#53B5EA',
                  curveness: 0.2,
                  width: '2'
                }
              },
              itemStyle: {
                emphasis: {
                  shadowColor: 'rgba(0, 0, 0, 0.2)',
                  shadowBlur: 10,
                  borderWidth: 0
                }
              }
            },
            {
              type: 'lines',
              coordinateSystem: 'cartesian2d',
              z: 1,
              zlevel: 2,
              animation: false,
              effect: {
                show: true,
                period: 5,
                trailLength: 0.01,
                symbolSize: 12,
                symbol: 'pin',
                loop: true,
                color: 'rgba(55,155,255,0.5)'
              },
              lineStyle: {
                normal: {
                  color: '#22AC38',
                  width: 0,
                  curveness: 0.2
                }
              },
              data: dxData
            }
          ]
        },
        true
      )
      if (myChart._$handlers.click) {
        myChart._$handlers.click.length = 0
      }
      myChart.on('click', function(param) {
        // 关系图的节点被点击时此方法被回调。
        let { searchName = '' } = param.data
        GetMagByName({ magName: searchName }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { id = '' } = data
            if (id !== '') {
              that.chooseId = id
              //强制要求更新期刊概况信息
              that.dictionary.map((item) => {
                that.$set(item, 'number', '')
              })
              that.$router.push({ path: '/details', query: { searchId: id } }) // 跳转地址
            }
          } else {
            const { message = '' } = res.data
            this.$message.error(message)
          }
        })
      })
    }
  }
}
</script>
<style scoped lang="scss">
/*.con_01 {*/
/*  width: 282px;*/
/*  height: 110px;*/
/*  background: #f5f7fd;*/
/*  margin-bottom: 5px;*/
/*}*/
.con_01 {
  width: 282px;
  height: 110px;
  background: #f5f7fd;
  margin-bottom: 5px;
  position: relative;
}
.conter_1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 0 25px;
}

.con_01_title {
  color: #666;
  font-size: 14px;
  margin-top: 10px;
}

.conter_3 {
  padding: 15px 0 25px;
}

.tj_year {
  color: #999;
  font-size: 14px;
  position: relative;
  top: -3px;
  right: -10px;
}
.postion {
  position: absolute;
  top: 40px;
  left: 30px;
  z-index: 100;
}
.postion1 {
  position: absolute;
  top: 40px;
  right: 30px;
  z-index: 100;
}
.tj_year1 {
  color: #999;
  font-size: 14px;
  position: relative;
  top: -3px;
  right: -10px;
}
.disDiv {
  display: flex;
}
.fengm {
  width: 166px;
  height: 240px;
  border: 1px solid #d4d4d4;
  margin-right: 30px;
}
.fengm img {
  float: left;
}
.detail {
  flex: 1;
  text-align: left;
}
.title_z {
  font-size: 20px;
  line-height: 30px;
  color: #333;
}
.title_e {
  font-size: 12px;
  line-height: 20px;
  color: #999;
}
.conter_4 {
  margin-top: 12px;
  display: flex;
}
.conter_4 ul li {
  line-height: 26px;
}
.conter_4 ul li p {
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  color: #666;
}
.conter_4 ul li span {
  font-size: 13px;
  color: #999;
}
.ul_1,
.ul_3 {
  width: 380px;
}
.ul_2 {
  width: 340px;
  margin: 0 20px;
}
</style>
