import { render, staticRenderFns } from "./mag.vue?vue&type=template&id=36a0d87a&scoped=true"
import script from "./mag.vue?vue&type=script&lang=js"
export * from "./mag.vue?vue&type=script&lang=js"
import style0 from "./mag.vue?vue&type=style&index=0&id=36a0d87a&prod&lang=scss"
import style1 from "./mag.vue?vue&type=style&index=1&id=36a0d87a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36a0d87a",
  null
  
)

export default component.exports